import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { GLOBAL_SPINNER_HTTP_CONTEXT } from '@data-terminal/utils';

import {
    ConsumptionsPerDevice,
    Material,
    ReportConsumptionsBody,
    ReportConsumptionsResponse,
} from '@data-terminal/shared-models';

@Injectable({
    providedIn: 'root',
})
export class ConsumptionsApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #backendUrl = inject(BACKEND_URL);
    readonly #orgRequestPrefix = inject(ORGANIZATION_REQUEST_PREFIX);

    readonly #materialListUrl = `${this.#backendUrl}${this.#orgRequestPrefix}materiallist`;
    readonly #reportConsumptionsUrl = `${this.#backendUrl}${this.#orgRequestPrefix}consumption/report`;
    readonly #recentMaterialsUrl = (deviceId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}consumption/recentMaterials/${deviceId}`;
    readonly #batchInformationUrl = (jobId: string): string =>
        `${this.#backendUrl}${this.#orgRequestPrefix}consumption/batchInformation/${jobId}`;

    private cachedMaterials?: Material[];

    public getMaterials(): Observable<Material[]> {
        if (this.cachedMaterials) {
            return of(this.cachedMaterials);
        }
        return this.#httpClient.get<Material[]>(this.#materialListUrl).pipe(
            tap((materialList) => {
                this.cachedMaterials = materialList;
            })
        );
    }

    public getRecentMaterials(deviceId: string): Observable<string[]> {
        return this.#httpClient.get<string[]>(this.#recentMaterialsUrl(deviceId), {
            params: {
                limit: 10,
            },
        });
    }

    public report(body: ReportConsumptionsBody): Observable<ReportConsumptionsResponse> {
        return this.#httpClient.post<ReportConsumptionsResponse>(this.#reportConsumptionsUrl, body, {
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }

    public getBatchInformationForJobIdPerDevice(jobId: string): Observable<ConsumptionsPerDevice> {
        return this.#httpClient.get<ConsumptionsPerDevice>(this.#batchInformationUrl(jobId), {
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }
}
