@if (state$ | async; as state) {
    @if (state.initialLoadDone) {
        @if (state.isSubmitting) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <mat-sidenav-container>
            <mat-sidenav-content>
                <div class="hdmui-container">
                    <header>
                        <mat-toolbar class="pure-time__toolbar">
                            <div>
                                <button
                                    mat-stroked-button
                                    (click)="logTime(state.machineSignInTimestamp, state.entries)"
                                    [disabled]="state.isSubmitting"
                                >
                                    {{ 'DC.PURE_TIME.LOG_TIME' | translate }}
                                </button>
                                <button
                                    mat-stroked-button
                                    (click)="submitTimes()"
                                    [disabled]="state.isSubmitting || !(state.entries | draftEntries).length"
                                >
                                    {{ 'DC.PURE_TIME.SUBMIT_TIMES' | translate }}
                                </button>
                            </div>
                        </mat-toolbar>
                    </header>
                    <section class="hdmui-content hdmui-p-0 hdmui-flex">
                        <div>
                            @if (state.entries | timeModeEntriesToCards; as cards) {
                                @if (cards.length) {
                                    <div class="pure-time__wrapper hdmui-container">
                                        <header class="pure-time__header hdmui-background-1">
                                            <div>
                                                <p>
                                                    {{
                                                        'DC.PURE_TIME.TOTAL_DURATION'
                                                            | translate
                                                                : {
                                                                      durationStr:
                                                                          state.entries
                                                                          | totalEntriesDurationMs
                                                                          | timeFormatter: true : true : true : false,
                                                                  }
                                                    }}
                                                </p>
                                            </div>
                                            <div>
                                                <p>{{ today | date: 'fullDate' }}</p>
                                            </div>
                                            <div>
                                                <button mat-button (click)="switchMode()">
                                                    <mat-icon
                                                        [svgIcon]="
                                                            mode === Mode.TIMELINE ? 'hdmui:tiles' : 'hdmui:calendarDay'
                                                        "
                                                    ></mat-icon>
                                                    {{
                                                        mode === Mode.TIMELINE
                                                            ? ('DC.PURE_TIME.CARDS_MODE' | translate)
                                                            : ('DC.PURE_TIME.TIMELINE_MODE' | translate)
                                                    }}
                                                </button>
                                            </div>
                                        </header>
                                        <mat-divider></mat-divider>
                                        <section class="hdmui-content hdmui-py-0 pure-time__container">
                                            <hdmui-info-box icon="hdmui:info" background="full">
                                                <p>
                                                    {{ 'DC.PURE_TIME.INFO' | translate }}
                                                </p>
                                            </hdmui-info-box>
                                            <div class="pure-time__schedule hdmui-mt-4">
                                                @if (
                                                    mode === Mode.TIMELINE &&
                                                        (state.machineSignInTimestamp
                                                            | machineSignInTimestampToHoursRange
                                                                : (timelineRefresher$ | async));
                                                    as hours
                                                ) {
                                                    @for (hour of hours; track hour) {
                                                        <div class="pure-time__schedule--timeline">
                                                            <p class="hdmui-m-0 hdmui-font-small">{{ hour }}:00</p>
                                                            @for (i of [0, 1, 2, 3]; track i) {
                                                                <mat-divider></mat-divider>
                                                            }
                                                        </div>
                                                    }
                                                    <div class="pure-time__schedule--timeline">
                                                        <p class="hdmui-m-0 hdmui-font-small">
                                                            {{ hours[hours.length - 1] + 1 }}:00
                                                        </p>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                }
                                                @for (card of cards; track card; let i = $index) {
                                                    <mat-card
                                                        class="hdmui-p-0"
                                                        [class.timeline-mode]="mode === Mode.TIMELINE"
                                                        [class.extend-on-hover]="
                                                            mode === Mode.TIMELINE &&
                                                            card.height < CARD_HOVER_EXTEND_THRESHOLD_PX
                                                        "
                                                        [class.hdmui-mb-4]="mode === Mode.CARDS"
                                                        [class.has-blue-indicator]="
                                                            card.entry.status === TimeModeStatus.DRAFT
                                                        "
                                                        [class.has-red-indicator]="
                                                            card.entry.status === TimeModeStatus.ERROR
                                                        "
                                                        [style.top]="
                                                            mode === Mode.TIMELINE
                                                                ? calcCardTopPx(
                                                                      cards,
                                                                      state.machineSignInTimestamp,
                                                                      i
                                                                  ) + 'px'
                                                                : 'unset'
                                                        "
                                                        [style.height]="
                                                            mode === Mode.TIMELINE ? card.height + 'px' : 'unset'
                                                        "
                                                        [style.margin-left]="
                                                            mode === Mode.TIMELINE
                                                                ? calcCardMarginLeft(cards, i) + 'px'
                                                                : 'unset'
                                                        "
                                                        [class.card-selected]="card.entry === selectedEntry"
                                                        (click)="openDetails(card.entry)"
                                                    >
                                                        <div
                                                            class="hdmui-p-2 card__content--wrapper"
                                                            [class.low-height]="
                                                                mode === Mode.TIMELINE &&
                                                                card.height <= LOW_CARD_THRESHOLD_PX
                                                            "
                                                        >
                                                            <h2
                                                                class="hdmui-font-serif hdmui-mx-3"
                                                                [class.hdmui-my-0]="mode === Mode.TIMELINE"
                                                                [class.hdmui-my-3]="mode === Mode.CARDS"
                                                            >
                                                                {{ card.entry.activity | activityNameById: activities }}
                                                            </h2>
                                                            <div class="pure-time__schedule--card-details">
                                                                @if (
                                                                    mode === Mode.CARDS ||
                                                                    card.height > LOW_CARD_THRESHOLD_PX
                                                                ) {
                                                                    <hdmui-key-value
                                                                        [label]="
                                                                            'DC.OPERATIONS.OPERATION_DETAILS.JOB_NUMBER'
                                                                                | translate
                                                                        "
                                                                        >{{ card.entry.jobId }}
                                                                    </hdmui-key-value>
                                                                }
                                                                <hdmui-key-value [label]="'*.duration' | translate"
                                                                    >{{
                                                                        card.entry.duration
                                                                            | timeFormatter: true : true : true : false
                                                                    }}
                                                                </hdmui-key-value>
                                                            </div>
                                                        </div>
                                                    </mat-card>
                                                }
                                            </div>
                                        </section>
                                    </div>
                                } @else {
                                    <hdmui-empty-states icon="hdmui:info">
                                        <hdmui-empty-states-title
                                            >{{ 'DC.PURE_TIME.LOG_TIME' | translate }}
                                        </hdmui-empty-states-title>
                                        <hdmui-empty-states-description
                                            >{{
                                                'DC.PURE_TIME.EMPTY_STATES.DESCRIPTION'
                                                    | translate
                                                        : {
                                                              logTimeButton: 'DC.PURE_TIME.LOG_TIME' | translate,
                                                              submitTimesButton:
                                                                  'DC.PURE_TIME.SUBMIT_TIMES' | translate,
                                                          }
                                            }}
                                        </hdmui-empty-states-description>
                                    </hdmui-empty-states>
                                }
                            }
                        </div>
                        <app-legal-line></app-legal-line>
                    </section>
                </div>
            </mat-sidenav-content>

            <mat-sidenav #detailsSidenav class="hdmui" mode="over" position="end">
                <div class="hdmui-container">
                    <header>
                        <mat-toolbar class="sidenav__toolbar">
                            <h3>
                                {{
                                    (selectedEntry?.status === TimeModeStatus.DRAFT ||
                                    selectedEntry?.status === TimeModeStatus.ERROR
                                        ? 'DC.PURE_TIME.EDIT.SIDENAV.EDIT.TITLE'
                                        : 'DC.PURE_TIME.EDIT.SIDENAV.SUBMITTED.TITLE'
                                    ) | translate
                                }}
                            </h3>
                            <div class="hdmui-spacer"></div>
                            <button
                                mat-icon-button
                                (click)="detailsSidenav.toggle()"
                                [title]="'*.action.close' | translate"
                            >
                                <mat-icon svgIcon="hdmui:x"></mat-icon>
                            </button>
                        </mat-toolbar>
                    </header>
                    @if (selectedEntry) {
                        @if (
                            selectedEntry.status === TimeModeStatus.DRAFT ||
                            selectedEntry.status === TimeModeStatus.ERROR
                        ) {
                            <section class="sidenav__content hdmui-content hdmui-p-0">
                                <data-terminal-log-time-form
                                    [editEntry]="selectedEntry"
                                    [activities]="activities"
                                    [lastLoggedActivityTimestamp]="
                                        calcLastEntryEndTimestampMs(state.entries, state.machineSignInTimestamp)
                                    "
                                    [machineClass]="machineClass"
                                    [isSubmitting]="state.isSubmitting"
                                    [operationSettings]="workstationResolvedData.operationSettings"
                                    (formValueChange)="onEditEntryFormValueChange($event)"
                                    (formValidityChange)="onEditEntryFormValidityChange($event)"
                                    (materialConsumptionsAllowedChange)="onMaterialConsumptionsAllowedChange($event)"
                                ></data-terminal-log-time-form>

                                @if (materialConsumptionsEnabled) {
                                    <data-terminal-custom-divider
                                        [text]="'DC.OPERATIONS_SETTINGS.MATERIAL_CONSUMPTION' | translate"
                                    ></data-terminal-custom-divider>

                                    <data-terminal-consumption-add-form
                                        [workstationId]="machineId"
                                        [allMaterials]="workstationResolvedData.materials"
                                        [batchAllowed]="!!workstationResolvedData.operationSettings?.batchReport"
                                        [consumptions]="this.editableConsumptions || []"
                                        (consumptionAdd)="addConsumption($event)"
                                    ></data-terminal-consumption-add-form>

                                    <mat-divider></mat-divider>

                                    <div class="hdmui-p-4">
                                        <data-terminal-consumptions-preview
                                            [consumptions]="this.editableConsumptions || []"
                                            (consumptionRemove)="removeConsumption($event)"
                                        ></data-terminal-consumptions-preview>
                                    </div>
                                }
                            </section>

                            <mat-divider></mat-divider>

                            <footer class="hdmui-px-3">
                                <button mat-button (click)="deleteEntry()" [disabled]="state.isSubmitting">
                                    {{ '*.delete' | translate }}
                                </button>
                                <button
                                    mat-stroked-button
                                    (click)="updateEntry()"
                                    [disabled]="!isEditEntryFormValid || state.isSubmitting"
                                >
                                    {{ '*.action.update' | translate }}
                                </button>
                            </footer>
                        } @else {
                            <section class="sidenav__content--submitted hdmui-content">
                                <hdmui-key-value [label]="'DC.MACHINE_CARD.ACTIVITY' | translate">
                                    {{ selectedEntry.activity | activityNameById: activities }}
                                </hdmui-key-value>
                                <hdmui-key-value [label]="'*.duration' | translate" class="hdmui-mb-4">
                                    {{ selectedEntry.duration | timeFormatter: true : true : true : false }}
                                </hdmui-key-value>
                                <hdmui-key-value
                                    [label]="'DC.NEW_OPERATION_DIALOG.JOB_ID' | translate"
                                    class="hdmui-mb-4"
                                >
                                    {{ selectedEntry.jobId || '-' }}
                                </hdmui-key-value>
                                <hdmui-key-value [label]="'DC.REPORT_LAST_AMOUNTS.GOOD' | translate">
                                    {{ selectedEntry?.goodAmount || '-' }}
                                </hdmui-key-value>
                                <hdmui-key-value
                                    [label]="'DC.REPORT_LAST_AMOUNTS.WASTE' | translate"
                                    class="hdmui-mb-4"
                                >
                                    {{ selectedEntry?.wasteAmount || '-' }}
                                </hdmui-key-value>
                                <hdmui-key-value [label]="'DC.REPORT_LAST_AMOUNTS.DIALOG.COMMENT' | translate">
                                    {{ selectedEntry?.comment || '-' }}
                                </hdmui-key-value>

                                @if (selectedEntry?.consumptions?.length) {
                                    <div class="hdmui-my-4">
                                        <data-terminal-custom-divider
                                            [text]="'DC.OPERATIONS_SETTINGS.MATERIAL_CONSUMPTION' | translate"
                                        ></data-terminal-custom-divider>
                                    </div>

                                    <data-terminal-consumptions-preview
                                        [editable]="false"
                                        [consumptions]="this.selectedEntry?.consumptions || []"
                                    ></data-terminal-consumptions-preview>
                                }
                            </section>
                        }
                    }
                </div>
            </mat-sidenav>
        </mat-sidenav-container>
    } @else {
        <data-terminal-loading-indicator></data-terminal-loading-indicator>
    }
}
