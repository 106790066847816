import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    NgModule,
    signal,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIcon } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDivider } from '@angular/material/divider';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButton, MatIconButton } from '@angular/material/button';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { Consumption, ConsumptionPreview, OperationAction, OperationActionType } from '@data-terminal/shared-models';
import { requireMinValueOptional, requireOneControl } from '@data-terminal/utils';
import {
    AmountDialogFormData,
    OperationAmountDetails,
} from 'projects/feature-workstation-details/src/lib/models/amount-report-details.model';
import { AmountReportDetailsModule } from '../../../amount-report-details/amount-report-details.component';
import { ConsumptionDialogData } from '../consumption-dialog/consumption-dialog.component';
import { ConsumptionAddFormComponent } from '../consumption-add-form/consumption-add-form.component';
import { ConsumptionsPreviewComponent } from '../consumptions-preview/consumptions-preview.component';
import { consumptionPreviewsToConsumptions } from '../../../../functions/consumption-preview-to-consumption.function';

const TICK_250 = 250;

export interface ReportLastAmountDialogData {
    dialogType: OperationActionType;
    i18nHeaderKey: string;
    plannedGoodAmount: number;
    plannedWasteAmount: number;
    currentGoodAmount: number;
    currentWasteAmount: number;
    counterBoxTimestamp: string;
    isManualMachine?: boolean;
    consumptionsDialogData?: ConsumptionDialogData;
}

export interface ReportLastAmountDialogClose {
    action: OperationAction;
    consumptions?: Consumption[];
}

@UntilDestroy()
@Component({
    templateUrl: './report-last-amounts-dialog.component.html',
    styleUrls: ['./report-last-amounts-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportLastAmountsDialogComponent implements AfterViewInit {
    @ViewChild('goodAmountInput') goodAmountInput!: ElementRef<HTMLInputElement>;

    public readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    public readonly OperationActionType = OperationActionType;
    public reportAmountsForm!: UntypedFormGroup;

    public selectedStep = 0;
    public readonly finalStep = 1;
    public readonly hasStepper = this.dialogData.consumptionsDialogData !== undefined;

    public formData$ = new BehaviorSubject<AmountDialogFormData | null>({
        goodAmount: 0,
        wasteAmount: 0,
    });
    public operationData$ = new BehaviorSubject<OperationAmountDetails | null>(null);

    public readonly consumptions = signal<ConsumptionPreview[]>([]);

    constructor(
        private readonly dialogRef: MatDialogRef<ReportLastAmountsDialogComponent, ReportLastAmountDialogClose>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ReportLastAmountDialogData
    ) {
        this.createFormGroup();
    }

    ngAfterViewInit(): void {
        if (!this.dialogData.isManualMachine) {
            setTimeout(() => {
                this.reportAmountsForm.markAllAsTouched();
                this.goodAmountInput.nativeElement.select();
                this.operationData$.next({
                    currentGood: this.dialogData.currentGoodAmount,
                    plannedGood: this.dialogData.plannedGoodAmount,
                    currentWaste: this.dialogData.currentWasteAmount,
                    plannedWaste: this.dialogData.plannedWasteAmount,
                });
                this.reportAmountsForm.updateValueAndValidity({ emitEvent: true });
            }, TICK_250);
        }
    }

    private get isFinalStep(): boolean {
        return this.selectedStep === this.finalStep;
    }

    public get primaryButtonText(): string {
        if (!this.hasStepper || this.isFinalStep) {
            return 'DC.REPORT_LAST_AMOUNTS.DIALOG.OKTEXT';
        } else {
            return '*.action.next';
        }
    }

    public get primaryButtonEnabled(): boolean {
        return this.reportAmountsForm.valid;
    }

    public get secondaryButtonText(): string {
        if (!this.hasStepper) {
            return 'DC.REPORT_LAST_AMOUNTS.DIALOG.CANCELTEXT';
        } else if (this.isFinalStep) {
            return '*.action.previous';
        } else {
            return 'DC.REPORT_LAST_AMOUNTS.DIALOG.CANCELTEXT';
        }
    }

    public onPrimaryButtonClick(): void {
        if (!this.hasStepper || this.isFinalStep) {
            const operationReportAction: OperationAction = {
                ...this.reportAmountsForm.value,
                actionType: this.dialogData.dialogType,
            };
            this.dialogRef.close({
                action: operationReportAction,
                consumptions: this.dialogData.consumptionsDialogData
                    ? consumptionPreviewsToConsumptions(this.consumptions())
                    : undefined,
            });
        } else {
            this.selectedStep = 1;
        }
    }

    public onSecondaryButtonClick(): void {
        if (!this.hasStepper) {
            this.dialogRef.close();
        } else if (this.isFinalStep) {
            this.selectedStep = 0;
        } else {
            this.dialogRef.close();
        }
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public isInputValid(): boolean {
        return this.reportAmountsForm.errors === null;
    }

    public onInputOut(): void {
        this.reportAmountsForm.markAllAsTouched();
    }

    public addConsumption(consumption: ConsumptionPreview): void {
        this.consumptions.update((consumptions) => [...consumptions, consumption]);
    }

    public removeConsumption(index: number): void {
        this.consumptions.update((consumptions) => consumptions.filter((_, i) => i !== index));
    }

    public static returnDialogConfigData(data: ReportLastAmountDialogData): MatDialogConfig {
        return {
            width: '320px',
            minHeight: '140px',
            maxHeight: '640px',
            height: '100%',
            data,
            disableClose: true,
            autoFocus: false,
        };
    }

    private startListenToValueChanges(): void {
        this.reportAmountsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
            this.formData$.next({
                goodAmount: data.goodAmount || 0,
                wasteAmount: data.wasteAmount || 0,
            });
        });
    }

    private createFormGroup(): void {
        if (this.dialogData.isManualMachine) {
            this.reportAmountsForm = new UntypedFormGroup({
                goodAmount: new UntypedFormControl(),
                wasteAmount: new UntypedFormControl(),
                comment: new UntypedFormControl(),
            });
        } else {
            this.reportAmountsForm = new UntypedFormGroup(
                {
                    goodAmount: new UntypedFormControl(),
                    wasteAmount: new UntypedFormControl(),
                    comment: new UntypedFormControl(),
                },
                [
                    requireOneControl('goodAmount', 'wasteAmount'),
                    requireMinValueOptional('goodAmount', 0),
                    requireMinValueOptional('wasteAmount', 0),
                ]
            );
            if (this.dialogData.dialogType === OperationActionType.STOP) {
                if (this.dialogData.counterBoxTimestamp.length > 0) {
                    this.reportAmountsForm.get('goodAmount')?.setValue(0);
                } else {
                    this.reportAmountsForm
                        .get('goodAmount')
                        ?.setValue(Math.max(this.dialogData.plannedGoodAmount - this.dialogData.currentGoodAmount, 0));
                }
            }
        }
        this.startListenToValueChanges();
    }
}

@NgModule({
    declarations: [ReportLastAmountsDialogComponent],
    exports: [ReportLastAmountsDialogComponent],
    imports: [
        HdmuiComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        AmountReportDetailsModule,
        NgxMaskDirective,
        MatIcon,
        MatStepperModule,
        ConsumptionAddFormComponent,
        MatDivider,
        ConsumptionsPreviewComponent,
        MatToolbar,
        MatButton,
        MatIconButton,
    ],
})
export class ReportLastAmountsDialogModule {}
