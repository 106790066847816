import { Injectable } from '@angular/core';
import { MimeType } from '@data-terminal/shared-models';
import FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class FileDownloaderService {
    public download(data: string, fileName: string, mimeType: MimeType): void {
        const blob: Blob = new Blob([data], { type: mimeType });

        FileSaver.saveAs(blob, fileName);
    }
}
