import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import {
    Activity,
    Consumption,
    ConsumptionPreview,
    MachineClass,
    Material,
    OperationSettingsEntry,
} from '@data-terminal/shared-models';

import { LogTimeFormComponent, LogTimeFormValue } from '../log-time-form/log-time-form.component';
import { ConsumptionAddFormComponent } from '../operations/operation-toolbar-buttons/consumption-add-form/consumption-add-form.component';
import { ConsumptionsPreviewComponent } from '../operations/operation-toolbar-buttons/consumptions-preview/consumptions-preview.component';
import { consumptionPreviewsToConsumptions } from '../../functions/consumption-preview-to-consumption.function';

export interface LogTimeDialogData {
    activities: Activity[];
    machineClass: MachineClass;
    lastLoggedActivityTimestamp: number;
    operationSettings: OperationSettingsEntry;
    materials: Material[];
    workstationId: string;
}

export interface LogTimeDialogResponse {
    logTimeFormValue: LogTimeFormValue;
    consumptions: Consumption[];
}

@Component({
    templateUrl: './log-time-dialog.component.html',
    styleUrl: './log-time-dialog.component.scss',
    standalone: true,
    imports: [
        HdmuiComponentsModule,
        TranslateModule,
        LogTimeFormComponent,
        ConsumptionAddFormComponent,
        ConsumptionsPreviewComponent,
        MatButton,
        MatDivider,
        MatIcon,
        MatToolbar,
        MatIconButton,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogTimeDialogComponent {
    readonly #dialogRef: MatDialogRef<LogTimeDialogComponent, LogTimeDialogResponse> = inject(MatDialogRef);

    public readonly data: LogTimeDialogData = inject(MAT_DIALOG_DATA);

    public readonly consumptions = signal<ConsumptionPreview[]>([]);

    public selectedStep = 0;
    public readonly finalStep = 1;

    public materialConsumptionsEnabled = false;

    public logTimeFormValue?: LogTimeFormValue;
    public isLogTimeFormValid = false;

    public onCancelDialog(): void {
        this.#dialogRef.close();
    }

    public onConfirmDialog(): void {
        if (this.logTimeFormValue && this.isLogTimeFormValid) {
            this.#dialogRef.close({
                logTimeFormValue: this.logTimeFormValue,
                consumptions: consumptionPreviewsToConsumptions(this.consumptions()),
            });
        }
    }

    public addConsumption(consumption: ConsumptionPreview): void {
        this.consumptions.update((consumptions) => [...consumptions, consumption]);
    }

    public removeConsumption(index: number): void {
        this.consumptions.update((consumptions) => consumptions.filter((_, i) => i !== index));
    }

    public onPrimaryButtonClick(): void {
        if (!this.isLogTimeFormValid) {
            return;
        }
        if (!this.materialConsumptionsEnabled || this.isFinalStep) {
            this.onConfirmDialog();
        } else {
            this.selectedStep = 1;
        }
    }

    public get secondaryButtonText(): string {
        if (!this.materialConsumptionsEnabled) {
            return '*.action.cancel';
        } else if (this.isFinalStep) {
            return '*.action.previous';
        } else {
            return '*.action.cancel';
        }
    }

    public get primaryButtonText(): string {
        if (!this.materialConsumptionsEnabled || this.isFinalStep) {
            return 'DC.PURE_TIME.LOG_TIME';
        } else {
            return '*.action.next';
        }
    }

    public onSecondaryButtonClick(): void {
        if (!this.materialConsumptionsEnabled) {
            this.onCancelDialog();
        } else if (this.isFinalStep) {
            this.selectedStep = 0;
        } else {
            this.onCancelDialog();
        }
    }

    public onLogTimeFormValueChange(value: LogTimeFormValue): void {
        this.logTimeFormValue = value;
    }

    public onLogTimeFormValidityChange(isValid: boolean): void {
        this.isLogTimeFormValid = isValid;
    }

    public onMaterialConsumptionsAllowedChange(materialConsumptionsAllowed: boolean): void {
        this.materialConsumptionsEnabled =
            materialConsumptionsAllowed && this.data.operationSettings.materialConsumption;
        if (!this.materialConsumptionsEnabled) {
            this.consumptions.set([]);
        }
    }

    private get isFinalStep(): boolean {
        return this.selectedStep === this.finalStep;
    }
}
