<mat-expansion-panel class="hdmui" [expanded]="true">
    <mat-expansion-panel-header matRipple>
        <mat-panel-title>
            <h2>
                {{ machineName }}
            </h2>
            <div class="hdmui-font-bold">({{ machineId }})</div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <section class="hdmui-content">
            @for (info of batchInformation | keyvalue; track info) {
                <p>
                    <span class="hdmui-font-bold">Operation:</span>&nbsp;
                    <span>{{ operationName | translateOperationName }}&nbsp;({{ info.key }} )</span>
                </p>
                <table mat-table [dataSource]="info.value" aria-describedby="batch-information-desc">
                    <ng-container matColumnDef="material">
                        <th mat-header-cell *matHeaderCellDef>{{ 'WORKSTEPS.TABLE.MATERIAL' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ element.materialId }}</td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>{{ 'AMOUNT' | translate }}</th>
                        <td mat-cell *matCellDef="let element">{{ element.consumption }}</td>
                    </ng-container>
                    <ng-container matColumnDef="batch">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'DC.REPORT_CONSUMPTION_DIALOG.BATCH_NUMBER' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.batchId }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            }
        </section>
    </ng-template>
</mat-expansion-panel>
