import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgModule,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe, DOCUMENT } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog } from '@angular/material/dialog';
import { catchError, filter, interval, Observable, of, take, tap } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HdmuiEmptyStatesModule, HdmuiIconsModule } from '@heidelberg/hdmui-angular';

import { CustomDividerModule, SearchInputComponent, SearchInputModule } from '@data-terminal/ui-presentational';
import {
    Machine,
    MachineClass,
    NewOperation,
    Operation,
    OperationCardAttribute,
    OperationFilter,
    OperationSettingsEntry,
    ROUTE_PARAMS,
} from '@data-terminal/shared-models';
import { findResolvedData, getParams } from '@data-terminal/utils';
import { BarcodeScanInputType, BarcodeSearchDialogService, OperationCardModule } from '@data-terminal/ui-smart';
import { OperationCardAttributeOrderService } from '@data-terminal/settings';
import { GLOBAL_RX_STATE, GlobalState, GlobalStateSelectors, OrganizationService } from '@data-terminal/data-access';

import { NewOperationDialogComponent } from './new-operation-dialog/new-operation-dialog.component';
import { filterOperations, hasAnyFilterApplied, searchInputFilter } from './operation-list.filter';
import { OperationService } from '../../../services/operation/operation.service';
import { JobService } from '../../../services/job/job.service';
import { WorkstationResolvedDataKeys } from '../../../services/resolvers/workstation-resolved-data-keys.enum';

const MIN_SEARCH_PHRASE_LENGTH = 1;
const REFRESH_INTERVAL_5000 = 5000;

export enum ExtraFunctionType {
    FINISHED_OPERATION = 'finishedOperation',
    OTHER_MACHINE_OPERATION = 'otherMachineOperation',
}

export enum ExtraFunctionCallSource {
    CODE_SCANNING = 'codeScanning',
    DEFAULT = 'default',
}

interface AutomaticExtraFunctionDetails {
    types: ExtraFunctionType[];
    source: ExtraFunctionCallSource;
}

interface ExtraFunctionsStorageData {
    type: ExtraFunctionType;
    searchPhrase: string;
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-operations-list',
    templateUrl: './operations-list.component.html',
    styleUrls: ['./operations-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationsListComponent implements OnChanges, OnInit, AfterViewInit {
    @ViewChild(SearchInputComponent) searchInputComponent!: SearchInputComponent;

    @Input() public operations: Operation[] = [];
    @Input() public selectedOperation!: Operation;
    @Input() public filter?: OperationFilter;
    @Input() public operationSettings?: OperationSettingsEntry;
    @Output() public filterClick = new EventEmitter<void>();
    @Output() public operationClick = new EventEmitter<Operation>();
    @Output() public operationCreation = new EventEmitter<NewOperation>();

    public showFilterBadge = false;
    public isTaskPilotEnabled = false;

    public notRunningOperations: Operation[] = [];
    public runningOperations: Operation[] = [];
    public finishedOperations: Operation[] = [];
    public otherDeviceOperations: Operation[] = [];
    public jobIdSuggestions: string[] = [];
    public extraFunctionsEnabled = false;
    public attrOrder$: Observable<string[]>;
    public attrOrder: string[] = [];
    public readonly machineClass: MachineClass;

    public searchPhrase = '';
    private readonly machineId: string;
    private readonly extraFunctionsStorageKey: string;

    public allMachinesData: Machine[] = [];

    private isInitiated = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly dialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly datePipe: DatePipe,
        private readonly translate: TranslateService,
        private readonly operationCardAttributeOrderService: OperationCardAttributeOrderService,
        private readonly barcodeSearchDialogService: BarcodeSearchDialogService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly operationService: OperationService,
        private readonly jobService: JobService,
        private readonly organizationService: OrganizationService,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>
    ) {
        this.machineClass =
            findResolvedData<MachineClass>(this.route.snapshot, WorkstationResolvedDataKeys.MACHINE_CLASS) ??
            MachineClass.DEFAULT;
        this.machineId = getParams(route)[ROUTE_PARAMS.machineId];
        this.extraFunctionsStorageKey = `LOADED_OTHER_OPERATIONS_${this.machineId}`;
        this.isTaskPilotEnabled = !!this.organizationService.organizationInfo.taskpilot;

        this.attrOrder$ = this.operationCardAttributeOrderService
            .getOperationCardOrderNames$(this.machineClass.toUpperCase() as MachineClass)
            .pipe(tap((order) => (this.attrOrder = order)));

        this.loadAdditionalOperationsFromStorage();

        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.data !== null && data.data !== undefined) {
                    this.allMachinesData = data.data.allMachines;
                }
                this.refreshOperation();
            });

        this.refreshOperation();

        interval(REFRESH_INTERVAL_5000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.refreshOperation();
                this.cdRef.detectChanges();
            });
    }

    public ngOnInit(): void {
        this.refreshOperation();
        this.focusSelectedOperation();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            const primaryKey = getParams(this.route)[ROUTE_PARAMS.primaryKey];

            const operationCardEl = this.document?.getElementById(primaryKey);
            const hdmuiContentWrapperEl = operationCardEl?.closest('.hdmui-content');

            if (operationCardEl && hdmuiContentWrapperEl) {
                const operationCardElRect = operationCardEl.getBoundingClientRect();
                const hdmuiContentWrapperElRect = hdmuiContentWrapperEl.getBoundingClientRect();

                hdmuiContentWrapperEl.scrollBy({
                    top: operationCardElRect.top - hdmuiContentWrapperElRect.top,
                    left: operationCardElRect.left - hdmuiContentWrapperElRect.left,
                    behavior: 'smooth',
                });
            }
        }, 1000);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.refreshOperation();
        this.refreshSelectedOperation();

        if (changes.filter) {
            this.showFilterBadge = hasAnyFilterApplied(this.filter);
        }
    }

    public onSearchInputChange(
        searchPhrase: string,
        automaticExtraFunctionDetails?: AutomaticExtraFunctionDetails
    ): void {
        this.searchPhrase = searchPhrase;
        this.refreshOperation();

        this.disableExtraFunctions();

        if (this.searchPhrase.length >= MIN_SEARCH_PHRASE_LENGTH) {
            this.jobService
                .getJobList(this.searchPhrase)
                .pipe(
                    take(1),
                    catchError(() => of([]))
                )
                .subscribe((jobs) => {
                    this.jobIdSuggestions = jobs.map(({ jobId }) => jobId);
                    this.extraFunctionsEnabled = this.jobIdSuggestions.some((jobId) => jobId === this.searchPhrase);

                    if (this.extraFunctionsEnabled) {
                        automaticExtraFunctionDetails?.types.forEach((type) => {
                            switch (type) {
                                case ExtraFunctionType.FINISHED_OPERATION:
                                    this.loadFinishedOperations();
                                    break;
                                case ExtraFunctionType.OTHER_MACHINE_OPERATION:
                                    this.loadOperationsByDeviceClass(automaticExtraFunctionDetails?.source);
                                    break;
                            }
                        });
                    }
                });
        } else {
            this.jobIdSuggestions = [];
        }

        this.refreshSelectedOperation();
    }

    public onBarcodeClick(): void {
        this.barcodeSearchDialogService
            .openBarcodeDialog(this.searchInputComponent, BarcodeScanInputType.SEARCH)
            .afterClosed()
            .pipe(take(1))
            .subscribe((extractedValue) => {
                const automaticallyLoadOtherDeviceClasses = !!this.operationSettings?.automaticallyLoadAllByDeviceClass;

                this.onSearchInputChange(
                    extractedValue,
                    automaticallyLoadOtherDeviceClasses
                        ? {
                              types: [ExtraFunctionType.OTHER_MACHINE_OPERATION],
                              source: ExtraFunctionCallSource.CODE_SCANNING,
                          }
                        : undefined
                );
            });
    }

    public onFilterClick(): void {
        this.filterClick.emit();
    }

    public onCardSelect($event: Operation): void {
        this.selectedOperation = $event;
        this.operationClick.emit($event);
    }

    public identify(index: number, item: Operation): string {
        return item.primaryKey;
    }

    public onOperationAdd(): void {
        const data: NewOperation = {
            noAmountsRequired: this.machineClass === 'ID_ManualPrePress',
        };
        this.dialog
            .open(NewOperationDialogComponent, {
                width: '320px',
                minHeight: '140px',
                maxHeight: '750px',
                height: '100%',
                disableClose: true,
                data,
            })
            .afterClosed()
            .pipe(filter((dialogData) => dialogData))
            .subscribe({
                next: (report: NewOperation) => {
                    this.operationCreation.emit(report);
                },
            });
    }

    private refreshOperation(): void {
        let operationsCopy = filterOperations(this.filter, this.operations);
        operationsCopy = searchInputFilter(
            this.searchPhrase,
            operationsCopy,
            this.datePipe,
            this.translate,
            this.attrOrder as OperationCardAttribute[]
        );
        this.sortOperations(operationsCopy);
    }

    private sortOperations(operations: Operation[]): void {
        this.notRunningOperations = [];
        this.runningOperations = [];
        operations.forEach((opr) => {
            if (
                opr.runningActivities === undefined ||
                opr.runningActivities.length === 0 ||
                (opr.runningActivities.length === 1 && opr.runningActivities[0] === '')
            ) {
                this.notRunningOperations.push(opr);
            } else {
                if (
                    this.allMachinesData.find((machine) => machine.machineId === this.machineId)?.runningOperation
                        ?.primaryKey === opr.primaryKey
                ) {
                    // is actual machine
                    if (!this.runningOperations.find((op) => op.primaryKey === opr.primaryKey)) {
                        this.runningOperations.push(opr);
                    }
                } else {
                    this.notRunningOperations.push(opr);
                }
            }
        });
    }

    private focusSelectedOperation(): void {
        const params = getParams(this.route);

        this.selectedOperation =
            this.operations.find((el) => el.primaryKey === params[ROUTE_PARAMS.primaryKey]) ||
            this.runningOperations[0] ||
            this.notRunningOperations[0];
    }

    public getMachineString(): string {
        return `${this.machineId}-${this.machineClass}`;
    }

    public loadFinishedOperations(): void {
        if (this.extraFunctionsEnabled) {
            this.operationService
                .completedOperationlist(this.machineId, this.searchPhrase)
                .pipe(take(1))
                .subscribe((data) => {
                    data.sort((a, b) => {
                        const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                        const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                        return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                    });
                    this.finishedOperations = data;
                    this.setExtraFunctionsStorageValue(ExtraFunctionType.FINISHED_OPERATION, this.searchPhrase);
                });
        }
    }

    public loadOperationsByDeviceClass(source?: ExtraFunctionCallSource): void {
        if (this.extraFunctionsEnabled) {
            this.operationService
                .operationlistByClass(this.machineId, this.searchPhrase)
                .pipe(take(1))
                .subscribe((data) => {
                    data.sort((a, b) => {
                        const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                        const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                        return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                    });
                    this.otherDeviceOperations = data;

                    if (
                        source === ExtraFunctionCallSource.CODE_SCANNING &&
                        data.length === 0 &&
                        this.operationSettings?.automaticallyCreateUnplanned
                    ) {
                        this.onCreateUnplannedWorkInstant();
                    }

                    this.setExtraFunctionsStorageValue(ExtraFunctionType.OTHER_MACHINE_OPERATION, this.searchPhrase);
                });
        }
    }

    private refreshSelectedOperation(): void {
        const params = getParams(this.route);
        if (!this.isInitiated) {
            this.isInitiated = true;
            this.focusSelectedOperation();
        }
        if (
            !this.runningOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.notRunningOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.finishedOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.otherDeviceOperations.find((op) => op.primaryKey === this.selectedOperation.primaryKey) &&
            !this.operations.find((op) => op.primaryKey === this.selectedOperation.primaryKey)
        ) {
            this.selectedOperation = {} as Operation;
            this.operationClick.emit({} as Operation);
        }
        const urlOperation = this.operations.find((op) => op.primaryKey === params[ROUTE_PARAMS.primaryKey]);
        if (urlOperation && this.selectedOperation.primaryKey === undefined) {
            this.selectedOperation = urlOperation;
            this.operationClick.emit(this.selectedOperation);
        }
    }

    public onCreateUnplannedWorkInstant(): void {
        this.operationCreation.emit({ jobId: this.searchPhrase });
    }

    private setExtraFunctionsStorageValue(type: ExtraFunctionType, searchPhrase: string): void {
        const storageData: ExtraFunctionsStorageData = { type, searchPhrase };

        sessionStorage.setItem(this.extraFunctionsStorageKey, JSON.stringify(storageData));
    }

    private loadAdditionalOperationsFromStorage(): void {
        const sessionStorageOtherOperations = sessionStorage.getItem(this.extraFunctionsStorageKey);

        if (sessionStorageOtherOperations !== null && sessionStorageOtherOperations !== undefined) {
            const storageData: ExtraFunctionsStorageData = JSON.parse(sessionStorageOtherOperations);

            if (storageData.searchPhrase && storageData.type) {
                this.onSearchInputChange(storageData.searchPhrase, {
                    types: [storageData.type],
                    source: ExtraFunctionCallSource.DEFAULT,
                });
            }
        }
    }

    private disableExtraFunctions(): void {
        this.extraFunctionsEnabled = false;
        this.finishedOperations = [];
        this.otherDeviceOperations = [];
        sessionStorage.removeItem(this.extraFunctionsStorageKey);
    }
}

@NgModule({
    declarations: [OperationsListComponent],
    exports: [OperationsListComponent],
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        OperationCardModule,
        CommonModule,
        TranslateModule,
        CustomDividerModule,
        MatDividerModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        SearchInputModule,
        MatBadgeModule,
        HdmuiEmptyStatesModule,
        HdmuiIconsModule,
    ],
})
export class OperationsListModule {}
