import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    concatMap,
    delay,
    filter,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    share,
    startWith,
    Subject,
    switchMap,
    tap,
    timer,
    zip,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    Activity,
    CreateOperation,
    DataTerminalError,
    ErrorCode,
    Machine,
    NewOperation,
    Operation,
    OperationAction,
    OperationActionRequest,
    RequestType,
    ROUTE_PARAMS,
    StartOperationActivity,
} from '@data-terminal/shared-models';
import {
    ApiRequestType,
    getParams,
    isNullOrUndefined,
    mapToRequestMetadataWithRetry,
    MS_IN_SECOND,
    RequestMetadata,
    RETRY_TIME_DELAY_MS,
} from '@data-terminal/utils';
import {
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
    OperationNavigationService,
} from '@data-terminal/data-access';

import { IOTResponseError } from 'projects/shared-models/src/lib/datatransfer';

import { OperationService } from '../../services/operation/operation.service';
import { OngoingOperationDialogService } from '../../services/operation/ongoing-operation-dialog.service';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { GeneralActivitiesService } from '../../services/general-activities/general-activities.service';
import { ApiRequestResponseService } from '../../../../../data-access/src/lib/api-request-response/api-request-response.service';
import { ErrorDialogComponent } from '../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { GenericUpdatesService } from '../../services/generic-updates/generic-updates.service';
import { NewOperationDialogService } from '../../services/new-operation-dialog/new-operation-dialog.service';

export interface OrderBagBody {
    machineId: string;
    jobId: string;
    page: number;
}

const TWENTY_SECONDS = 20;
const LOAD_AGAIN_DELAY = 2000;

@UntilDestroy()
@Injectable()
export class OperationPresenter {
    private readonly machineId: string = '';
    private readonly OPERATION_LIST_REQUEST_INTERVAL = TWENTY_SECONDS * MS_IN_SECOND;

    private readonly startActivityTrigger$ = new Subject<StartOperationActivity>();
    private readonly performActionTrigger$ = new Subject<OperationActionRequest>();
    private readonly createOperationTrigger$ = new Subject<CreateOperation>();
    private readonly selectOperationTrigger$ = new ReplaySubject<string>(1);

    private readonly actionPerformed$ = new Subject<void>();
    private readonly activityStarted$ = new Subject<void>();
    private readonly deliveryRequested$ = new Subject<void>();

    private readonly updateOnOperationListRefresh$ = new Subject<RequestMetadata<Operation>>();

    private runningOperation!: Operation | undefined;
    private allMachinesData: Machine[] = [];
    private operationListInitialLoad = true;
    private checkOperationListTimestamp = new Date().toISOString();
    private counterboxTimestamp = '';

    public operationList$: Observable<RequestMetadata<Operation[]>>;
    public selectedOperation$: Observable<RequestMetadata<Operation>>;

    private readonly handleOngoingOperations = (
        stream$: Observable<StartOperationActivity>
    ): Observable<StartOperationActivity> =>
        stream$.pipe(
            switchMap((startActivity: StartOperationActivity) => {
                if (
                    this.runningOperation &&
                    this.allMachinesData.find((machine) => machine.machineId === this.machineId)?.runningOperation
                        ?.primaryKey === this.runningOperation.primaryKey
                ) {
                    if (this.runningOperation.primaryKey !== startActivity.operation.primaryKey) {
                        return this.ongoingOperationDialogService.openOngoingOperationDialog(
                            startActivity,
                            this.runningOperation,
                            this.allMachinesData.find((machine) => machine.machineId === this.machineId)
                                ?.machineClass === 'ID_ManualPrePress',
                            this.counterboxTimestamp
                        );
                    }
                    return of(startActivity);
                } else {
                    return of(startActivity);
                }
            })
        );

    private readonly startActivity$ = this.startActivityTrigger$.pipe(
        this.handleOngoingOperations,
        mergeMap((startActivity: StartOperationActivity) =>
            this.operationService.startActivity(startActivity).pipe(
                filter((d) => !d.isLoading),
                tap((d) => {
                    if (d.data && 'error' in d.data) {
                        this.genericUpdateService.updateOperation(startActivity.operation.primaryKey);
                    }
                }),
                filter((d) => d.data !== null && d.data !== undefined && !('error' in d.data)),
                map((d) => d as RequestMetadata<Operation>),
                tap({
                    complete: () => {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                        this.activityStarted$.next();
                    },
                })
            )
        ),
        share()
    );

    private readonly operationAction$ = this.performActionTrigger$.pipe(
        mergeMap((operationRequestAction: OperationActionRequest) =>
            this.operationService.updateOperationAction(operationRequestAction).pipe(
                filter((d) => !d.isLoading),
                tap({
                    complete: () => {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                        this.actionPerformed$.next();
                    },
                })
            )
        ),
        share()
    );

    public readonly createOperation$ = this.createOperationTrigger$.pipe(
        mergeMap((createOperation) => {
            function getOperationCreateRequestType(errorId: ErrorCode): RequestType {
                switch (errorId) {
                    case ErrorCode.ERR_OPERATIONCREATE_MSG_SIZE_EXCEEDED:
                        return RequestType.OPERATIONCREATE_MSG_SIZE_EXEEDED;
                    case ErrorCode.ERR_OPERATIONCREATE_OPERATION_UNDEFINED:
                        return RequestType.OPERATIONCREATE_OPERATION_UNDEFINED;
                    default:
                        return RequestType.OTHER;
                }
            }

            let timeout = true;
            const newOperation = createOperation.newOperation;
            return this.operationService.createNewOperation({ ...createOperation, machineId: this.machineId }).pipe(
                tap({
                    next: (data) => {
                        if (data.data !== null && data.data !== undefined) {
                            timeout = false;
                            if ('errorcode' in data.data) {
                                // Case: IOTResponseError
                                const errorData = data.data as object as IOTResponseError;
                                if (errorData.errorcode !== undefined) {
                                    this.dialog
                                        .open(ErrorDialogComponent, {
                                            disableClose: true,
                                            data: {
                                                errorCode: errorData.errorcode,
                                                errorMessage: JSON.stringify({
                                                    payload: errorData.payload,
                                                    request: errorData.request,
                                                    deviceId: errorData.deviceId,
                                                }),
                                                requestType: errorData.request,
                                            },
                                        })
                                        .afterClosed()
                                        .subscribe({
                                            next: (error: IOTResponseError) => {
                                                if (error.payload !== undefined) {
                                                    const prevDialogData = (error.payload as CreateOperation)
                                                        .newOperation;
                                                    this.newOperationDialogService
                                                        .openDialogAndFilter(prevDialogData)
                                                        .subscribe({
                                                            next: (report: NewOperation) => {
                                                                this.triggerCreateOperation(report);
                                                            },
                                                        });
                                                }
                                            },
                                        });
                                }
                            }
                            if ('errorId' in data.data) {
                                // Case: DataTerminalError
                                const errorData = data.data as object as DataTerminalError;
                                this.dialog.open(ErrorDialogComponent, {
                                    disableClose: true,
                                    data: {
                                        errorCode: errorData.errorId,
                                        requestType: getOperationCreateRequestType(errorData.errorId as ErrorCode),
                                    },
                                });
                            }
                        }
                    },
                    complete: () => {
                        if (timeout) {
                            this.dialog.open(ErrorDialogComponent, {
                                disableClose: true,
                                data: {
                                    errorCode: ErrorCode.ERR_OPERATIONCREATE_TIMEOUT.toString(),
                                    errorMessage: JSON.stringify({
                                        payload: newOperation,
                                        request: RequestType.OPERATIONCREATE_TIMEOUT,
                                        deviceId: this.machineId,
                                    }),
                                    requestType: RequestType.OPERATIONCREATE_TIMEOUT,
                                },
                            });
                        }
                    },
                })
            );
        }),
        filter((d) => !d.isLoading),
        tap((data) => {
            if (!data.isLoading && data.data !== null && data.data !== undefined && 'primaryKey' in data.data) {
                this.triggerSelectOperation(data.data.primaryKey);
                this.router.navigate([data.data.primaryKey], { relativeTo: this.activatedRoute });
            }
        })
    );

    private readonly selectOperation$ = this.selectOperationTrigger$.pipe(
        switchMap((primaryKey) => this.operationService.getOperationRequestMetadata(primaryKey)),
        startWith({ isLoading: true })
    );

    private readonly updateSelectOperation$ = this.genericUpdateService.selectedOperationUpdate$().pipe(
        switchMap((primaryKey) => this.operationService.getOperationRequestMetadata(primaryKey)),
        filter((d) => !d.isLoading)
    );

    constructor(
        private readonly operationNavigation: OperationNavigationService,
        private readonly operationService: OperationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ongoingOperationDialogService: OngoingOperationDialogService,
        private readonly userMachinesService: UserMachinesService,
        private readonly generalActivitiesService: GeneralActivitiesService,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly dialog: MatDialog,
        private readonly genericUpdateService: GenericUpdatesService,
        private readonly newOperationDialogService: NewOperationDialogService,
        private readonly router: Router
    ) {
        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.data !== null && data.data !== undefined) {
                    this.allMachinesData = data.data.allMachines;
                }
            });

        this.machineId = getParams(this.activatedRoute)[ROUTE_PARAMS.machineId];

        this.selectedOperation$ = merge(
            this.startActivity$,
            this.operationAction$,
            this.selectOperation$,
            this.updateSelectOperation$,
            this.updateOnOperationListRefresh$
        ).pipe(
            tap((d) => {
                if (d.data?.running && d.data.runningOnMachine === this.machineId) {
                    this.runningOperation = d.data;
                }
            })
        );

        this.operationList$ = this.listenForOperationsList().pipe(
            filter((data) => !data.isLoading),
            tap((data) => this.setNavigation(data)),
            tap((data) => {
                const primaryKey = this.allMachinesData.find((machine) => machine.machineId === this.machineId)
                    ?.runningOperation?.primaryKey;

                this.runningOperation = data.data?.find((op) => op.primaryKey === primaryKey);
            }),
            tap((data) => {
                const primaryKey = getParams(this.activatedRoute)[ROUTE_PARAMS.primaryKey];
                const selectedOperation = data.data?.find((ele) => ele.primaryKey === primaryKey);
                if (selectedOperation) {
                    this.updateOnOperationListRefresh$.next({ data: selectedOperation, isLoading: false });
                } else {
                    this.selectOperationTrigger$.next(primaryKey);
                }
            }),
            map((data) => {
                data.data?.sort((a, b) => {
                    const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
                    const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
                    return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
                });
                return data;
            })
        );
    }

    public triggerStartOperationActivity(
        activity: Activity,
        operation: Operation,
        machineId: string,
        counterBoxTimestamp = ''
    ): void {
        this.counterboxTimestamp = counterBoxTimestamp;
        this.startActivityTrigger$.next({ activity, operation, machineId });
    }

    public triggerSendOperationAction(operationAction: OperationAction, machineId: string, primaryKey: string): void {
        this.performActionTrigger$.next({ operationAction, machineId, primaryKey });
    }

    public triggerCreateOperation(operation: NewOperation): void {
        this.createOperationTrigger$.next({ newOperation: operation });
    }

    public triggerRequestDeliverySent(): void {
        this.deliveryRequested$.next();
    }

    public triggerSelectOperation(primaryKey: string): void {
        this.selectOperationTrigger$.next(primaryKey);
    }

    public triggerOrderBag(primaryKey: string): Observable<string | object> {
        const body: OrderBagBody = {
            machineId: this.machineId,
            jobId: primaryKey,
            page: 1,
        };
        return this.apiRequestResponseService.sendApiRequest<OrderBagBody, string>(ApiRequestType.ORDERBAG, body).pipe(
            filter((data) => !data.isLoading),
            filter((data) => data.data !== undefined),
            map((data) => data.data || 'ERROR')
        );
    }

    public getOperationActionSent(): Observable<void> {
        return this.actionPerformed$;
    }

    public getSelectedOperation$(): Observable<RequestMetadata<Operation>> {
        return this.selectedOperation$;
    }

    private _checkOperationList(): Observable<RequestMetadata<Operation[]>> {
        return of({}).pipe(
            // of() is necessary since the returned observable is reused but different timestamps are needed
            switchMap(() => {
                const updateCheckOperationListTimestamp = new Date().toISOString();
                return this.operationService
                    .checkOperationListUpdate(this.machineId, this.checkOperationListTimestamp)
                    .pipe(
                        filter((data) => data || this.operationListInitialLoad),
                        switchMap(() =>
                            this.operationService.getOperationList(this.machineId).pipe(
                                tap(() => (this.operationListInitialLoad = false)),
                                mapToRequestMetadataWithRetry({
                                    baseDelayMs: RETRY_TIME_DELAY_MS,
                                }),
                                filter((metadata) => !!metadata?.data)
                            )
                        ),
                        tap((d) => {
                            if (d) {
                                this.checkOperationListTimestamp = updateCheckOperationListTimestamp;
                            }
                        })
                    );
            })
        );
    }

    private listenForOperationsList(): Observable<RequestMetadata<Operation[]>> {
        const interval$ = timer(0, this.OPERATION_LIST_REQUEST_INTERVAL);

        const triggerLoadListAgain$ = merge(this.activityStarted$, this.actionPerformed$, this.deliveryRequested$).pipe(
            delay(LOAD_AGAIN_DELAY)
        );

        const allMachinesData = this.allMachinesData.find((machine) => machine.machineId === this.machineId);
        if (allMachinesData !== undefined) {
            const runningOperationPrimaryKey = allMachinesData.runningOperation?.primaryKey;
            if (this.isPrimaryKeyCorrect(runningOperationPrimaryKey)) {
                const otherMachineOperation$ = this.operationService
                    .getOperationRequestMetadata(runningOperationPrimaryKey as string)
                    .pipe(filter((data) => !data.isLoading));
                const fullOperationList$ = zip(this._checkOperationList(), otherMachineOperation$).pipe(
                    map((x) => {
                        return this.createFullOperationList(x);
                    }),
                    mapToRequestMetadataWithRetry({
                        baseDelayMs: RETRY_TIME_DELAY_MS,
                    })
                );
                return merge(triggerLoadListAgain$, interval$, this.createOperation$).pipe(
                    concatMap(() => fullOperationList$),
                    share(),
                    startWith({ isLoading: true })
                );
            }
        }
        return merge(triggerLoadListAgain$, interval$, this.createOperation$).pipe(
            concatMap(() => this._checkOperationList()),
            share(),
            startWith({ isLoading: true })
        );
    }

    private isPrimaryKeyCorrect(primaryKey: string | undefined): boolean {
        return primaryKey !== undefined && primaryKey !== null && !primaryKey.startsWith('_') && primaryKey.length > 0;
    }

    private createFullOperationList(
        operations: [RequestMetadata<Operation[]>, RequestMetadata<Operation>]
    ): Operation[] {
        const fullOperationList: Operation[] = [];
        const getOperationListData = operations[0].data;
        if (isNullOrUndefined(getOperationListData)) {
            throw Error('getOperationList Data is undefined');
        }
        const otherMachineOperationData = operations[1].data;
        if (isNullOrUndefined(otherMachineOperationData)) {
            throw Error('otherMachineOperationData Data is undefined');
        }
        for (const operation of [...getOperationListData.concat(otherMachineOperationData)]) {
            if (!fullOperationList.find((op) => op.primaryKey === operation.primaryKey)) {
                fullOperationList.push(operation);
            }
        }

        return this.sortOperationList(fullOperationList);
    }

    private sortOperationList(operationList: Operation[]): Operation[] {
        return operationList.sort((a, b) => {
            const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
            const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
            return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
        });
    }

    private setNavigation(metadata: RequestMetadata<Operation[]>): void {
        if (metadata.data) {
            const machineRunningOperation = this.allMachinesData.find(
                (machine) => machine.machineId === this.machineId
            )?.runningOperation;
            const runningOperations =
                metadata.data.filter(
                    (op) =>
                        op.runningActivities.length > 0 &&
                        this.allMachinesData.find((machine) => machine.machineId === this.machineId)?.runningOperation
                            ?.primaryKey === op.primaryKey
                ).length +
                (machineRunningOperation?.primaryKey !== undefined &&
                machineRunningOperation?.primaryKey !== '' &&
                metadata.data.filter((op) => op.primaryKey === machineRunningOperation?.primaryKey).length === 0
                    ? 1
                    : 0);
            this.operationNavigation.updateOperationTab({
                badge: runningOperations,
                isDisabled: false,
            });
        }
    }
}
