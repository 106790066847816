import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    of,
    startWith,
    switchMap,
    take,
    tap,
    timer,
} from 'rxjs';
import { DateTime } from 'luxon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { Activity, MachineClass, OperationSettingsEntry, TimeModeEntry } from '@data-terminal/shared-models';
import {
    MS_IN_SECOND,
    optionalValueWhitelistedValidator,
    SECONDS_IN_MIN,
    TimeFormatterPipeModule,
} from '@data-terminal/utils';
import { HelpButtonComponent, LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';

import { JobService } from '../../services/job/job.service';
import { DurationFormattingService } from '../../services/duration-formatting/duration-formatting.service';
import { DURATION_STR_PATTERN } from '../../functions/duration-str-to-ms.function';

function maxDurationValidator(differenceMinutes: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valueMatch = control.value.match(DURATION_STR_PATTERN);
        if (valueMatch) {
            const hours = valueMatch[1] ? parseInt(valueMatch[1], 10) : 0;
            const minutes = valueMatch[2] ? parseInt(valueMatch[2], 10) : 0;

            const calculatedCurrentMinutes = 60 * hours + minutes;

            if (calculatedCurrentMinutes > differenceMinutes) {
                return { maxDurationSeconds: { value: differenceMinutes * 60 } };
            }
        }

        return null;
    };
}

function minDurationValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valueMatch = control.value.match(DURATION_STR_PATTERN);
        if (valueMatch) {
            const hours = valueMatch[1] ? parseInt(valueMatch[1], 10) : 0;
            const minutes = valueMatch[2] ? parseInt(valueMatch[2], 10) : 0;

            if (hours === 0 && !minutes) {
                return { minDurationSeconds: { value: 0 } };
            }
        }

        return null;
    };
}

interface LogTimeForm {
    activity: FormControl<Activity>;
    duration: FormControl<string>;
    jobId: FormControl<string | undefined>;
    goodAmount: FormControl<number | undefined>;
    wasteAmount: FormControl<number | undefined>;
    comment: FormControl<string | undefined>;
}

export interface LogTimeFormValue {
    activity: Activity;
    duration: string;
    jobId: string | undefined;
    goodAmount: number | undefined;
    wasteAmount: number | undefined;
    comment: string | undefined;
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-log-time-form',
    templateUrl: './log-time-form.component.html',
    styleUrls: ['./log-time-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        MatInputModule,
        MatSelectModule,
        TimeFormatterPipeModule,
        MatAutocompleteModule,
        CommonModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        HelpButtonComponent,
        LoadingIndicatorComponentModule,
        MatProgressSpinner,
    ],
})
export class LogTimeFormComponent implements OnInit {
    readonly #fb = inject(FormBuilder);
    readonly #jobService = inject(JobService);
    readonly #durationFormattingService = inject(DurationFormattingService);

    @Input()
    lastLoggedActivityTimestamp!: number;

    @Input()
    activities: Activity[] = [];

    @Input()
    machineClass!: MachineClass;

    @Input()
    operationSettings!: OperationSettingsEntry;

    @Input()
    isSubmitting!: boolean;

    @Input()
    editEntry?: TimeModeEntry;

    @Output()
    formValueChange = new EventEmitter<LogTimeFormValue>();

    @Output()
    formValidityChange = new EventEmitter<boolean>();

    @Output()
    materialConsumptionsAllowedChange = new EventEmitter<boolean>();

    public readonly MachineClass = MachineClass;

    public jobIdSuggestions$!: Observable<string[]>;
    public activitiesMap = new Map<Activity['groupName'], Activity[]>();

    public form!: FormGroup<LogTimeForm>;

    public lastLoggedActivityAgoMinutes$ = new BehaviorSubject<number>(0);

    public jobIdSuggestionsLoading = false;

    ngOnInit(): void {
        this.prepareActivitiesMap();
        this.initFG();
        this.listenForJobIdSuggestions();
        this.listenForLastLoggedActivityAgoMinutesChanges();

        this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.formValueChange.emit(this.form.value as LogTimeFormValue);
            this.formValidityChange.emit(this.form.valid);
        });
    }

    public formatDurationString(): void {
        const formattedValue = this.#durationFormattingService.formatDurationString(this.form.controls.duration.value);

        if (formattedValue) {
            this.form.controls.duration.setValue(formattedValue);
        }
    }

    private prepareActivitiesMap(): void {
        (this.activities || []).forEach((activity) => {
            const existingActivities = this.activitiesMap.get(activity.groupName);

            if (existingActivities) {
                this.activitiesMap.set(activity.groupName, [...existingActivities, activity]);
            } else {
                this.activitiesMap.set(activity.groupName, [activity]);
            }
        });
    }

    private listenForLastLoggedActivityAgoMinutesChanges(): void {
        this.lastLoggedActivityAgoMinutes$.next(this.calcLastLoggedActivityAgoMinutes());

        timer(0, 1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.lastLoggedActivityAgoMinutes$.next(this.calcLastLoggedActivityAgoMinutes());
            });

        this.lastLoggedActivityAgoMinutes$
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe((lastLoggedActivityAgoMinutes) => {
                const durationFC = this.form.controls.duration;

                durationFC.clearValidators();
                durationFC.setValidators(
                    this.getDurationValidators(
                        this.editEntry
                            ? lastLoggedActivityAgoMinutes + this.editEntry.duration / MS_IN_SECOND / SECONDS_IN_MIN
                            : lastLoggedActivityAgoMinutes
                    )
                );

                if (!this.editEntry && !durationFC.touched) {
                    durationFC.setValue(`${lastLoggedActivityAgoMinutes}m`);
                    this.formatDurationString();
                }

                durationFC.updateValueAndValidity();

                this.formValueChange.emit(this.form.value as LogTimeFormValue);
                this.formValidityChange.emit(this.form.valid);
            });
    }

    private listenForJobIdSuggestions(): void {
        const JOB_SUGGESTION_MIN_LENGTH = 1;

        const jobIdFC: FormControl = this.form.controls.jobId;

        this.jobIdSuggestions$ = jobIdFC.valueChanges.pipe(
            startWith(jobIdFC.value),
            untilDestroyed(this),
            distinctUntilChanged(),
            tap(() => {
                this.jobIdSuggestionsLoading = true;
            }),
            debounceTime(1000),
            switchMap((jobId: string | undefined) =>
                jobId !== undefined && jobId.length >= JOB_SUGGESTION_MIN_LENGTH
                    ? this.#jobService.getJobList(jobId).pipe(
                          take(1),
                          catchError(() => of([]))
                      )
                    : of([])
            ),
            map((jobList) => jobList.map((job) => job.jobId)),
            tap((jobList) => {
                jobIdFC.setValidators(optionalValueWhitelistedValidator(jobList));
                jobIdFC.updateValueAndValidity();

                this.materialConsumptionsAllowedChange.emit(jobIdFC.value && jobIdFC.valid);
                this.jobIdSuggestionsLoading = false;
            })
        );
    }

    private initFG(): void {
        const selectedEntryActivity = this.activities.find((activity) => this.editEntry?.activity === activity.actId);

        this.form = this.#fb.group<LogTimeForm>({
            activity: this.#fb.nonNullable.control<Activity>(
                {
                    value: selectedEntryActivity ? selectedEntryActivity : this.activities[0],
                    disabled: this.isSubmitting,
                },
                {
                    validators: [Validators.required],
                }
            ),
            duration: this.#fb.nonNullable.control<string>(
                {
                    value: this.editEntry?.duration
                        ? `${this.editEntry.duration / MS_IN_SECOND / SECONDS_IN_MIN}m`
                        : '0m',
                    disabled: this.isSubmitting,
                },
                this.getDurationValidators(0)
            ),
            jobId: this.#fb.nonNullable.control<string | undefined>(
                {
                    value: this.editEntry?.jobId,
                    disabled: this.isSubmitting,
                },
                [optionalValueWhitelistedValidator([])]
            ),
            goodAmount: this.#fb.nonNullable.control<number | undefined>(
                { value: this.editEntry?.goodAmount, disabled: this.isSubmitting },
                [Validators.min(0)]
            ),
            wasteAmount: this.#fb.nonNullable.control<number | undefined>(
                {
                    value: this.editEntry?.wasteAmount,
                    disabled: this.isSubmitting,
                },
                [Validators.min(0)]
            ),
            comment: this.#fb.nonNullable.control<string | undefined>({
                value: this.editEntry?.comment,
                disabled: this.isSubmitting,
            }),
        });

        this.formatDurationString();
    }

    private getCalculatedMinutesFromDate(date: DateTime): number {
        return 60 * date.hour + date.minute + date.second / 60;
    }

    private getDurationValidators(maxDuration: number): ValidatorFn[] {
        return [
            Validators.required,
            Validators.pattern(DURATION_STR_PATTERN),
            maxDurationValidator(maxDuration),
            minDurationValidator(),
        ];
    }

    private calcLastLoggedActivityAgoMinutes(): number {
        return Math.floor(
            this.getCalculatedMinutesFromDate(DateTime.now()) -
                this.getCalculatedMinutesFromDate(DateTime.fromMillis(this.lastLoggedActivityTimestamp))
        );
    }
}
