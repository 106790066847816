<div class="hdmui hdmui-m-0 request-batch-information hdmui-container">
    <header>
        <h2>{{ 'DC.REQUEST_BATCH_INFORMATION.TITLE' | translate }}</h2>
    </header>

    <section class="hdmui-content request-batch-information__content">
        <form [formGroup]="jobSelectionForm">
            <mat-form-field>
                <mat-label>{{ 'DC.OPERATION_CARD.JOBNUMBER' | translate }}</mat-label>
                <input matInput formControlName="jobId" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    @for (suggestion of jobIdSuggestions$ | async; track suggestion) {
                        <mat-option [value]="suggestion">
                            {{ suggestion }}
                        </mat-option>
                    }
                </mat-autocomplete>
                @if (jobIdFromControl.hasError('required')) {
                    <mat-error>{{ '*.required' | translate }}</mat-error>
                }
                @if (jobIdFromControl.hasError('valueNotInList')) {
                    <mat-error>
                        {{ 'DC.NEW_OPERATION_DIALOG.ERROR.JOB_ID_NOT_FOUND' | translate }}
                    </mat-error>
                }
            </mat-form-field>
        </form>
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="cancel()">
            {{ '*.action.cancel' | translate }}
        </button>
        <button mat-flat-button (click)="requestBatchInformation()" [disabled]="!jobSelectionForm.valid">
            {{ '*.ok' | translate }}
        </button>
    </footer>
</div>
