import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, Observable, of, take, tap } from 'rxjs';

import { ConsumptionsPerDevice } from '@data-terminal/shared-models';

import { RequestBatchInformationDialogComponent } from '../../dialogs/request-batch-information-dialog/request-batch-information-dialog.component';
import { BatchInformationDialogComponent } from '../../dialogs/batch-information-dialog/batch-information-dialog.component';
import { ConsumptionsApiService } from '../../../../../feature-workstation-details/src/lib/services/consumptions-api/consumptions-api.service';

export interface BatchInformationDialogData {
    jobId: string;
    batchInformation: ConsumptionsPerDevice;
}

@Injectable({
    providedIn: 'root',
})
export class BatchInformationDialogService {
    readonly #dialog = inject(MatDialog);
    readonly #consumptionsApiService = inject(ConsumptionsApiService);

    public openRequestBatchInformationDialog(): void {
        this.#dialog.open<RequestBatchInformationDialogComponent>(RequestBatchInformationDialogComponent);
    }

    public openBatchInformationDialog(jobId: string, batchInformation: ConsumptionsPerDevice): void {
        this.#dialog.open<BatchInformationDialogComponent, BatchInformationDialogData>(
            BatchInformationDialogComponent,
            {
                width: '680px',
                maxHeight: '99vh',
                data: {
                    jobId,
                    batchInformation,
                },
                autoFocus: false,
            }
        );
    }

    public requestBatchInformation(jobId: string): Observable<ConsumptionsPerDevice> {
        return this.#consumptionsApiService.getBatchInformationForJobIdPerDevice(jobId).pipe(
            take(1),
            tap((batchInformation: ConsumptionsPerDevice) => this.openBatchInformationDialog(jobId, batchInformation)),
            catchError(() => of({}))
        );
    }
}
