<div class="hdmui hdmui-m-0 wrapper hdmui-container">
    <header>
        <mat-toolbar class="dialog-toolbar">
            <h2>
                {{ 'DC.PURE_TIME.LOG_TIME' | translate }}
            </h2>
            <button mat-icon-button (click)="onCancelDialog()">
                <mat-icon svgIcon="hdmui:x"></mat-icon>
            </button>
        </mat-toolbar>

        @if (materialConsumptionsEnabled) {
            <p class="hdmui-font-small hdmui-font-bold hdmui-mb-0">{{ '*.step' | translate }} {{ selectedStep + 1 }}</p>
            <div class="stepper">
                <div class="stepper__button" [class.stepper__button--active]="selectedStep === 0"></div>
                <div class="stepper__button" [class.stepper__button--active]="selectedStep === 1"></div>
            </div>

            <p class="hdmui-mt-0">
                {{ (selectedStep === 0 ? 'DC.PURE_TIME.LOG_TIME' : 'DC.REPORT_CONSUMPTION_DIALOG.TITLE') | translate }}
            </p>

            <mat-divider></mat-divider>

            @if (selectedStep === 1) {
                <data-terminal-consumption-add-form
                    [workstationId]="data.workstationId"
                    [allMaterials]="data.materials"
                    [batchAllowed]="data.operationSettings.batchReport"
                    [consumptions]="consumptions()"
                    (consumptionAdd)="addConsumption($event)"
                ></data-terminal-consumption-add-form>
                <mat-divider></mat-divider>
            }
        }
    </header>

    <section class="hdmui-content">
        <data-terminal-consumptions-preview
            [class.hidden]="!(materialConsumptionsEnabled && selectedStep === 1)"
            [consumptions]="consumptions()"
            (consumptionRemove)="removeConsumption($event)"
        ></data-terminal-consumptions-preview>
        <data-terminal-log-time-form
            [class.hidden]="materialConsumptionsEnabled && selectedStep === 1"
            [activities]="data.activities"
            [lastLoggedActivityTimestamp]="data.lastLoggedActivityTimestamp"
            [machineClass]="data.machineClass"
            [operationSettings]="data.operationSettings"
            (formValueChange)="onLogTimeFormValueChange($event)"
            (formValidityChange)="onLogTimeFormValidityChange($event)"
            (materialConsumptionsAllowedChange)="onMaterialConsumptionsAllowedChange($event)"
        ></data-terminal-log-time-form>
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="onSecondaryButtonClick()">
            {{ secondaryButtonText | translate }}
        </button>
        <button mat-flat-button (click)="onPrimaryButtonClick()" [disabled]="!isLogTimeFormValid">
            {{ primaryButtonText | translate }}
        </button>
    </footer>
</div>
