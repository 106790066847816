import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { MachineClass, Material, OperationSettingsEntry } from '@data-terminal/shared-models';
import { SettingsOperationsService } from '@data-terminal/feature-settings';
import { findResolvedData } from '@data-terminal/utils';

import { WorkstationResolvedDataKeys } from '../workstation-resolved-data-keys.enum';
import { ConsumptionsApiService } from '../../consumptions-api/consumptions-api.service';

export const defaultOperationSettings: OperationSettingsEntry = {
    automaticallyLoadAllByDeviceClass: false,
    automaticallyCreateUnplanned: false,
    materialConsumption: false,
    batchReport: false,
} as OperationSettingsEntry;

export interface WorkstationResolvedData {
    operationSettings: OperationSettingsEntry;
    materials: Material[];
}

export const workstationDataResolver: ResolveFn<WorkstationResolvedData> = (
    route: ActivatedRouteSnapshot
    /* state: RouterStateSnapshot */
): Observable<WorkstationResolvedData> => {
    const machineClass: MachineClass | null = findResolvedData<MachineClass>(
        route,
        WorkstationResolvedDataKeys.MACHINE_CLASS
    );

    if (!machineClass) {
        throw new Error('Wrong route resolver configuration: machineClass unknown');
    }

    const consumptionsApiService = inject(ConsumptionsApiService);
    const settingsOperationsService = inject(SettingsOperationsService);

    return settingsOperationsService.getOperationSettings().pipe(
        filter((settings) => !!settings.length),
        map(
            (allOperationSettings) =>
                allOperationSettings.find((entry) => entry.deviceClass.toUpperCase() === machineClass.toUpperCase()) ||
                defaultOperationSettings
        ),
        switchMap((operationSettings) => {
            const materials$ = operationSettings.materialConsumption ? consumptionsApiService.getMaterials() : of([]);

            return materials$.pipe(
                map((materials) => ({
                    operationSettings,
                    materials,
                }))
            );
        })
    );
};
