import { Consumption, ConsumptionPreview } from '@data-terminal/shared-models';

export function consumptionPreviewToConsumption(consumptionPreview: ConsumptionPreview): Consumption {
    return {
        consumption: consumptionPreview.consumption,
        materialId: consumptionPreview.materialId,
        type: consumptionPreview.type,
        batchId: consumptionPreview.batchId || undefined,
    };
}

export function consumptionPreviewsToConsumptions(consumptionPreviews: ConsumptionPreview[]): Consumption[] {
    return consumptionPreviews.map(consumptionPreviewToConsumption);
}
