import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';

import { ConsumptionEntity, ConsumptionsPerDevice, Machine, MimeType } from '@data-terminal/shared-models';
import { GLOBAL_RX_STATE, GlobalState, GlobalStateSelectors } from '@data-terminal/data-access';

import {
    BatchInformation,
    OperationBatchInformation,
} from '../../dialogs/batch-information-dialog/single-machine-batch-information/single-machine-batch-information.component';
import { FileDownloaderService } from '../../../../../utils/src/lib/services/file-donwloader.service';

export interface BatchCsvData {
    deviceId: string;
    operationName: string;
    materialId: string;
    consumption: number;
    batchId: string;
}

@Injectable({
    providedIn: 'root',
})
export class BatchInformationService {
    #fileDownloaderService = inject(FileDownloaderService);
    readonly #globalState: RxState<GlobalState> = inject(GLOBAL_RX_STATE);
    allMachines: Machine[] | undefined = this.#globalState.get()[GlobalStateSelectors.USER_MACHINES].data?.allMachines;

    prepareBatchInformation(consumptions: ConsumptionEntity[]): OperationBatchInformation {
        return consumptions.reduce(
            (acc, consumption) => {
                const operationId = this.#getOperationIdFromOperationPrimaryKey(consumption.operationPrimaryKey);
                if (!acc[operationId]) {
                    acc[operationId] = [];
                }

                acc[operationId].push({
                    deviceId: consumption.deviceId,
                    operationName: consumption.operationName ?? '',
                    materialId: consumption.materialId,
                    consumption: consumption.consumption,
                    batchId: consumption.batchId ?? '',
                });

                return acc;
            },
            {} as { [key: string]: BatchInformation[] }
        );
    }

    findMachineNameById(machineId: string): string {
        return this.allMachines?.find((machine) => machine.machineId === machineId)?.machineName ?? '';
    }

    exportBatchInformationToCsv(batchInformation: ConsumptionsPerDevice, jobId: string): void {
        const csvData = this.#convertBatchInformationToCsvData(batchInformation);
        this.#fileDownloaderService.download(csvData, `Batch_Info_${jobId}`, MimeType.CSV);
    }

    #convertBatchInformationToCsvData(batchInformation: ConsumptionsPerDevice): string {
        const mappedData = this.#mapBatchInformationToCsvData(batchInformation);
        const header = Object.keys(mappedData[0]).join(',');
        const rows = mappedData.map((obj) =>
            Object.values(obj)
                .map((value) => `"${value}"`)
                .join(',')
        );
        return [header, ...rows].join('\n');
    }

    #mapBatchInformationToCsvData(batchInformation: ConsumptionsPerDevice): BatchCsvData[] {
        return Object.values(batchInformation)
            .reduce((acc: ConsumptionEntity[], entry: ConsumptionEntity[]) => {
                return acc.concat(...Object.values(entry));
            }, [])
            .map((singleBatchInformation: ConsumptionEntity) => {
                return {
                    deviceId: singleBatchInformation.deviceId,
                    operationName: singleBatchInformation.operationName,
                    materialId: singleBatchInformation.materialId,
                    consumption: singleBatchInformation.consumption,
                    batchId: singleBatchInformation.batchId,
                } as BatchCsvData;
            });
    }

    #getOperationIdFromOperationPrimaryKey(operationPrimaryKey: string): string {
        if (operationPrimaryKey.includes('__')) {
            return operationPrimaryKey.split('__')[1];
        } else {
            return operationPrimaryKey;
        }
    }
}
