<form [formGroup]="newOperationForm" class="report">
    <hdmui-base-dialog
        [okEnabled]="newOperationForm.valid"
        [dialogOptions]="option"
        (cancelClicked)="onCancelDialog()"
        (okClicked)="onConfirmDialog()"
        [contentNoPadding]="true"
        [okText]="'DC.NEW_OPERATION_DIALOG.OK_TEXT' | translate"
        [cancelText]="'DC.NEW_OPERATION_DIALOG.CANCEL_TEXT' | translate"
    >
        <div class="report-form">
            <h2 class="hdmui-m-3">{{ 'DC.NEW_OPERATION_DIALOG.HEADER' | translate }}</h2>
            <div class="hdmui-m-3 report-form__container">
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__info">
                    {{ 'DC.NEW_OPERATION_DIALOG.REQUIRE_INFO' | translate }}
                </p>

                <mat-form-field>
                    <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.JOB_ID' | translate }}</mat-label>
                    <input type="string" required matInput formControlName="jobId" [matAutocomplete]="auto" />
                    @if (jobIdSuggestionsLoading) {
                        <mat-progress-spinner matSuffix mode="indeterminate" [diameter]="24"></mat-progress-spinner>
                    }
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        @for (option of jobIdSuggestions$ | async; track option) {
                            <mat-option [value]="option">
                                {{ option }}
                            </mat-option>
                        }
                    </mat-autocomplete>
                    @if (newOperationForm.get('jobId')?.hasError('valueNotInList')) {
                        <mat-error>
                            {{ 'DC.NEW_OPERATION_DIALOG.ERROR.JOB_ID_NOT_FOUND' | translate }}
                        </mat-error>
                    }
                </mat-form-field>

                <mat-divider class="hdmui-my-4"></mat-divider>

                @if (!data.noAmountsRequired) {
                    <mat-form-field class="hdmui-mt-0 hdmui-pt-0">
                        <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.GOOD_AMOUNT' | translate }}</mat-label>
                        <input matInput formControlName="goodAmount" mask="separator.0" />
                    </mat-form-field>
                }
                @if (!data.noAmountsRequired) {
                    <mat-form-field>
                        <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.WASTE_AMOUNT' | translate }}</mat-label>
                        <input matInput formControlName="wasteAmount" mask="separator.0" />
                    </mat-form-field>
                }

                <div style="display: flex">
                    <mat-form-field class="hdmui-me-1">
                        <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.MAKE_READY_TIME' | translate }}</mat-label>
                        <input
                            matInput
                            [placeholder]="'DC.NEW_OPERATION_DIALOG.HOURS' | translate"
                            type="number"
                            min="0"
                            formControlName="makeReadyTimeHour"
                        />
                    </mat-form-field>

                    <mat-form-field class="hdmui-ms-1">
                        <input
                            type="number"
                            min="0"
                            max="59"
                            matInput
                            [placeholder]="'DC.NEW_OPERATION_DIALOG.MINUTES' | translate"
                            formControlName="makeReadyTimeMinutes"
                        />
                    </mat-form-field>
                </div>

                <div style="display: flex">
                    <mat-form-field class="hdmui-me-1">
                        <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.PRODUCTION_RUN_TIME' | translate }}</mat-label>
                        <input
                            matInput
                            [placeholder]="'DC.NEW_OPERATION_DIALOG.HOURS' | translate"
                            type="number"
                            min="0"
                            formControlName="productionRunTimeHours"
                        />
                    </mat-form-field>

                    <mat-form-field class="hdmui-ms-1">
                        <input
                            type="number"
                            min="0"
                            max="59"
                            matInput
                            [placeholder]="'DC.NEW_OPERATION_DIALOG.MINUTES' | translate"
                            formControlName="productionRunTimeMinutes"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </hdmui-base-dialog>
</form>
