import { FormControl, FormGroup } from '@angular/forms';

export enum ShapeCutterTaskType {
    ALL = 'ALL',
    SEMIFINISH = 'SEMIFINISH',
    TOOL = 'TOOL',
}

export interface RequestDeliveryBody {
    primaryKey: string;
    endDate?: number;
    amount?: number;
    operatorEmail?: string;
    shapeCutterTaskInfo?: ShapeCutterTaskType;
}

export type RequestDeliveryPartialData = Pick<
    RequestDeliveryBody,
    'endDate' | 'amount' | 'operatorEmail' | 'shapeCutterTaskInfo'
>;

export interface RequestDeliveryForm {
    targetTime: FormGroup<TargetTimeForm>;
    // to be implemented
    // sheetNumber: FormControl<string>;
    amount: FormControl<number | undefined>;
    operatorEmail: FormControl<string | undefined>;
}

export type RequestDeliveryFormValue = Partial<{
    targetTime: Partial<{ hours: number | undefined; minutes: number | undefined }>;
    amount: number | undefined;
    operatorEmail: string | undefined;
}>;

export interface TargetTimeForm {
    hours: FormControl<number | undefined>;
    minutes: FormControl<number | undefined>;
}

export interface RequestDeliveryDialogData {
    deliveryOption: ShapeCutterTaskType;
}
