import { Pipe, PipeTransform } from '@angular/core';

import { ItemType, MachineClass, Operation, Task, TaskStatus } from '@data-terminal/shared-models';

export interface RequestDeliveryOptionState {
    isRequestDeliveryPossible: boolean;
    isToolOptionEnabled: boolean;
    isSemiFinishOptionEnabled: boolean;
}
@Pipe({
    name: 'requestDeliveryOptionState',
    standalone: true,
})
export class RequestDeliveryOptionStatePipe implements PipeTransform {
    public transform(
        operation: Operation,
        deviceClass: MachineClass,
        isCurrentUserAssistant: boolean
    ): RequestDeliveryOptionState {
        let isRequestDeliveryPossible;
        let isToolOptionEnabled = false;
        let isSemiFinishOptionEnabled = false;

        if (isCurrentUserAssistant) {
            isRequestDeliveryPossible = false;
        } else {
            if (deviceClass.toLowerCase() !== MachineClass.ID_SHAPECUTTER.toLowerCase()) {
                isRequestDeliveryPossible =
                    this.hasOperationNoTask(operation) ||
                    !!operation.tasks?.every((task) => this.hasTaskCloseStatus(task));
            } else {
                const toolTask = operation.tasks?.find((task) => task.itemType === ItemType.Tool);
                const semiFinishTask = operation.tasks?.find((task) => task.itemType === ItemType.Semifinish);
                isRequestDeliveryPossible =
                    this.hasOperationNoTask(operation) ||
                    !!operation.tasks?.some((task) => this.hasTaskCloseStatus(task));
                isToolOptionEnabled =
                    !!operation.specialOperationInfo &&
                    operation.specialOperationInfo !== '' &&
                    (!toolTask || this.hasTaskCloseStatus(toolTask));
                isSemiFinishOptionEnabled = !semiFinishTask || this.hasTaskCloseStatus(semiFinishTask);
            }
        }

        return {
            isRequestDeliveryPossible,
            isToolOptionEnabled,
            isSemiFinishOptionEnabled,
        };
    }

    private hasOperationNoTask(operation: Operation): boolean {
        return !operation.tasks || operation.tasks?.length === 0;
    }

    private hasTaskCloseStatus(task: Task): boolean {
        return task.status === TaskStatus.close_ok || task.status === TaskStatus.close_timeout;
    }
}
