import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { filter, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';

import {
    ActivityState,
    Consumption,
    MachineClass,
    Material,
    OperationAction,
    OperationActionType,
    OperationSettingsEntry,
    WorkStepLink,
} from '@data-terminal/shared-models';
import { OrganizationInfo } from '@data-terminal/data-access';

import {
    ReportLastAmountDialogClose,
    ReportLastAmountDialogData,
    ReportLastAmountsDialogComponent,
} from './report-last-amounts-dialog/report-last-amounts-dialog.component';
import { ConsumptionDialogComponent, ConsumptionDialogData } from './consumption-dialog/consumption-dialog.component';
import {
    RequestDeliveryDialogData,
    RequestDeliveryPartialData,
    ShapeCutterTaskType,
} from '../../../models/request-delivery.model';
import { RequestDeliveryDialogComponent } from './request-delivery-dialog/request-delivery-dialog.component';
import { RequestDeliveryOptionState } from '../../../pipes/request-delivery-option-state.pipe';

@Component({
    selector: 'data-terminal-operation-toolbar-buttons',
    templateUrl: './operation-toolbar-buttons.component.html',
    styleUrls: ['./operation-toolbar-buttons.component.scss'],
})
export class OperationToolbarButtonsComponent implements OnChanges, OnInit {
    @Input() public opState!: ActivityState;
    @Input() public plannedGoodAmount!: number;
    @Input() public plannedWasteAmount!: number;
    @Input() public currentGoodAmount!: number;
    @Input() public currentWasteAmount!: number;
    @Input() public stopButtonOnly = false;
    @Input() public isCounterBox = false;
    @Input() public counterBoxTimestamp = '';
    @Input() public captainFlag = false;
    @Input() public isManualMachine = false;
    @Input() public isRequestDeliveryButtonVisible = false;
    @Input() public requestDeliveryOptionState: RequestDeliveryOptionState | undefined;
    @Input() public operationSettings?: OperationSettingsEntry;
    @Input() public organizationInfo?: OrganizationInfo;
    @Input() public deviceId?: string;
    @Input() public materials: Material[] = [];
    @Input() public workStepLink?: WorkStepLink;

    @Output() public sendReport: EventEmitter<OperationAction> = new EventEmitter<OperationAction>();
    @Output() public consumptionsReport: EventEmitter<Consumption[]> = new EventEmitter<Consumption[]>();
    @Output() public requestDelivery: EventEmitter<RequestDeliveryPartialData> =
        new EventEmitter<RequestDeliveryPartialData>();

    public areActionButtonsDisabled = true;
    public isStopButtonDisabled = true;
    public isStopHighlighted = false;
    public isPauseHighlighted = false;

    protected readonly MachineClass = MachineClass;
    protected readonly ShapeCutterTaskType = ShapeCutterTaskType;

    constructor(private readonly dialog: MatDialog) {}

    public ngOnInit(): void {
        this.updateButtonsConfig();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.opState) {
            this.updateButtonsConfig();
        }
    }

    public get pauseButtonDisabled(): boolean {
        return this.areActionButtonsDisabled || !this.captainFlag;
    }

    public get stopButtonDisabled(): boolean {
        return this.isStopButtonDisabled || !this.captainFlag;
    }

    public get reportButtonDisabled(): boolean {
        return this.areActionButtonsDisabled || !this.captainFlag || this.isCounterBox || this.isManualMachine;
    }

    public get consumptionsButtonDisabled(): boolean {
        return (
            this.areActionButtonsDisabled ||
            !this.captainFlag ||
            !this.operationSettings?.materialConsumption ||
            !this.organizationInfo?.consumptionReport
        );
    }

    public onPauseButtonClick(): void {
        if (this.pauseButtonDisabled) {
            return;
        }

        const headerKey = this.isManualMachine
            ? 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER_MANUAL_DEVICE'
            : 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER';

        this.openReportLastAmountsDialog(OperationActionType.PAUSE, headerKey, true);
    }

    public onStopButtonClick(): void {
        if (this.stopButtonDisabled) {
            return;
        }

        if (this.stopButtonOnly) {
            this.sendReport.emit({
                actionType: OperationActionType.STOP,
            } as OperationAction);
        } else {
            const headerKey = this.isManualMachine
                ? 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER_MANUAL_DEVICE'
                : 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER';

            this.openReportLastAmountsDialog(OperationActionType.STOP, headerKey, true);
        }
    }

    public onReportButtonClick(): void {
        if (this.reportButtonDisabled) {
            return;
        }

        this.openReportLastAmountsDialog(OperationActionType.ADD_REPORT, 'DC.REPORT_LAST_AMOUNTS.ADD_REPORT_HEADER');
    }

    public onConsumptionButtonClick(): void {
        if (this.consumptionsButtonDisabled) {
            return;
        }

        this.dialog
            .open<ConsumptionDialogComponent, ConsumptionDialogData, Consumption[]>(ConsumptionDialogComponent, {
                disableClose: true,
                autoFocus: false,
                data: {
                    allMaterials: this.materials,
                    batchAllowed: !!this.operationSettings?.batchReport,
                    workStepLink: this.workStepLink,
                    workstationId: this.deviceId || '',
                },
                maxHeight: '90vh',
                height: '100%',
                width: '100%',
                maxWidth: '620px',
            })
            .afterClosed()
            .subscribe((consumptions) => {
                this.consumptionsReport.emit(consumptions || []);
            });
    }

    public onRequestDeliveryButtonClick(): void {
        if (
            this.operationSettings?.deviceClass?.toLocaleLowerCase() !== MachineClass.ID_SHAPECUTTER.toLocaleLowerCase()
        ) {
            this.openRequestDeliveryDialog(ShapeCutterTaskType.SEMIFINISH);
        }
    }

    public onRequestDeliveryOptionClick(deliveryOption: ShapeCutterTaskType): void {
        this.openRequestDeliveryDialog(deliveryOption);
    }

    private openRequestDeliveryDialog(deliveryOption: ShapeCutterTaskType): void {
        this.dialog
            .open<RequestDeliveryDialogComponent, RequestDeliveryDialogData>(RequestDeliveryDialogComponent, {
                disableClose: true,
                autoFocus: false,
                maxHeight: '90vh',
                width: '360px',
                data: {
                    deliveryOption,
                },
            })
            .afterClosed()
            .pipe(
                take(1),
                filter((data) => !!data)
            )
            .subscribe((requestDeliveryData: RequestDeliveryPartialData) => {
                this.requestDelivery.emit(requestDeliveryData);
            });
    }

    private openReportLastAmountsDialog(
        dialogType: OperationActionType,
        i18nHeaderKey: string,
        withMaterialConsumption = false
    ): void {
        const allowConsumption =
            withMaterialConsumption &&
            !!this.operationSettings?.materialConsumption &&
            !!this.organizationInfo?.consumptionReport;

        const getFormData: () => ReportLastAmountDialogData = () => {
            return {
                dialogType,
                i18nHeaderKey,
                plannedGoodAmount: this.plannedGoodAmount,
                plannedWasteAmount: this.plannedWasteAmount,
                currentGoodAmount: this.currentGoodAmount,
                currentWasteAmount: this.currentWasteAmount,
                counterBoxTimestamp: this.counterBoxTimestamp,
                isManualMachine: this.isManualMachine,
                consumptionsDialogData: allowConsumption
                    ? {
                          allMaterials: this.materials,
                          batchAllowed: !!this.operationSettings?.batchReport,
                          workStepLink: this.workStepLink,
                          workstationId: this.deviceId || '',
                      }
                    : undefined,
            };
        };

        this.dialog
            .open<ReportLastAmountsDialogComponent, ReportLastAmountDialogData, ReportLastAmountDialogClose>(
                ReportLastAmountsDialogComponent,
                {
                    disableClose: true,
                    autoFocus: false,
                    data: getFormData(),
                    minHeight: '140px',
                    maxHeight: allowConsumption ? '90vh' : '640px',
                    height: '100%',
                    width: '100%',
                    maxWidth: '620px',
                }
            )
            .afterClosed()
            .pipe(filter((d) => !!d))
            .subscribe((data) => {
                if (data) {
                    this.sendReport.emit(data.action);

                    if (data.consumptions) {
                        this.consumptionsReport.emit(data.consumptions);
                    }
                }
            });
    }

    private updateButtonsConfig(): void {
        this.areActionButtonsDisabled = true;
        this.isStopButtonDisabled = true;
        this.isPauseHighlighted = false;
        this.isStopHighlighted = false;

        if (this.opState === ActivityState.SETUP || this.opState === ActivityState.IN_PROGRESS) {
            this.areActionButtonsDisabled = false;
            this.isStopButtonDisabled = false;
            return;
        }

        if (this.opState === ActivityState.COMPLETED) {
            this.isStopHighlighted = true;
            return;
        }

        if (this.opState === ActivityState.PAUSED || this.opState === ActivityState.SUSPENDED) {
            this.isPauseHighlighted = true;
            if (this.opState === ActivityState.SUSPENDED) {
                this.isStopButtonDisabled = false;
            }
        }
    }
}

@NgModule({
    declarations: [OperationToolbarButtonsComponent],
    exports: [OperationToolbarButtonsComponent],
    imports: [MatButtonModule, MatIconModule, CommonModule, MatMenuModule, TranslateModule],
})
export class OperationToolbarButtonsModule {}
