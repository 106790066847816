import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { Activity, ActivityRequired, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { getAllParams } from '../get-params-from-path.function';
import { FeatureOperationServiceModule } from '../../../operation-service.module';
import { OperationNavigationService } from '@data-terminal/data-access';
import { ActivitiesApiService } from '../../activities-api/activities-api.service';
import { WorkstationResolvedDataKeys } from '../workstation-resolved-data-keys.enum';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class ActivityListResolver {
    constructor(
        private readonly otherActivitiesService: ActivitiesApiService,
        private readonly operationNav: OperationNavigationService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Activity[]> {
        const machineId = getAllParams(route)[ROUTE_PARAMS.machineId];
        return this.otherActivitiesService.getActivities(machineId).pipe(
            map((data) => data || []),
            tap((data) => this.setOperationNavigation(data))
        );
    }

    private setOperationNavigation(data: Activity[]): void {
        const operation =
            data.filter((activity) => activity.required.includes(ActivityRequired.OPERATION) && activity.running)
                .length || 0;
        const otherActivity =
            data.filter((activity) => activity.required.includes(ActivityRequired.OTHER_ACTIVITY) && activity.running)
                .length || 0;

        this.operationNav.setInitialState({
            [ActivityRequired.OPERATION]: {
                isDisabled: false,
                badge: operation,
            },
            [ActivityRequired.OTHER_ACTIVITY]: {
                isDisabled: !!operation,
                badge: otherActivity,
            },
        });
    }
}

export function machineActivityListResolver(): ResolveData {
    return { [WorkstationResolvedDataKeys.ACTIVITIES]: ActivityListResolver };
}
