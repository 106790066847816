<div class="hdmui hdmui-m-0 hdmui-container">
    <header>
        <h2>{{ 'BATCH_INFORMATION.TITLE' | translate }} - {{ dialogData.jobId }}</h2>
    </header>

    <section class="hdmui-content">
        @if (!(dialogData.batchInformation | keyvalue).length) {
            <p>{{ 'BATCH_INFORMATION.NO_BATCH_INFORMATION_AVAILABLE' | translate }}</p>
        } @else {
            @for (singleMachineInformation of dialogData.batchInformation | keyvalue; track singleMachineInformation) {
                <data-terminal-single-machine-batch-information
                    [machineId]="singleMachineInformation.key"
                    [consumptions]="singleMachineInformation.value"
                ></data-terminal-single-machine-batch-information>
            }
        }
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="close()">
            {{ '*.action.close' | translate }}
        </button>
        @if ((dialogData.batchInformation | keyvalue).length) {
            <button mat-flat-button (click)="exportBatchInformation()">
                {{ 'DC.BATCH_INFORMATION.EXPORT' | translate }}
            </button>
        }
    </footer>
</div>
