import { Routes } from '@angular/router';

import { ActivityRequired, ROUTE_PARAMS, ROUTE_PATH, RouteData } from '@data-terminal/shared-models';

import { OperationsPageComponent } from './pages/operations-page/operations-page.component';
import { ActivitiesPageComponent } from './pages/activities-page/activities-page.component';
import { breadcrumbsWorkstationResolver } from './services/resolvers/breadcrumbs-workstation/breadcrumbs-workstation.resolver';
import { machineActivityListResolver } from './services/resolvers/activity-list/activity-list-resolver.service';
import { OperationDetailsPageComponent } from './pages/operation-details-page/operation-details-page.component';
import { operationDetailsResolver } from './services/resolvers/operation-details/operation-details.resolver';
import { operationDetailsNameResolver } from './services/resolvers/operation-details-name/operation-details-name.resolver';
import { OperationComponent } from './components/operations/operation/operation.component';
import { machineClassResolver } from './services/resolvers/machine-class/machine-class.resolver';
import { CommentWorkflowPageComponent } from './pages/comment-workflow-page/comment-workflow-page/comment-workflow-page.component';
import { PureTimeModePageComponent } from './pages/pure-time-mode-page/pure-time-mode.page.component';
import { machineSignInTimestampResolver } from './services/resolvers/machine-sign-in-timestamp/machine-sign-in-timestamp.resolver';
import { MachineGuardType, UserMachineGuard } from '@data-terminal/feature-dashboards-data-access';
import { WorkstationResolvedDataKeys } from './services/resolvers/workstation-resolved-data-keys.enum';
import { workstationDataResolver } from './services/resolvers/workstation-data/workstation-data.resolver';

const DC_APP_TITLE = 'DC.APP.TITLE';

const CHILDREN: Routes = [
    {
        path: `:${ROUTE_PARAMS.machineId}/${ROUTE_PATH.timeLogging}`,
        component: PureTimeModePageComponent,
        canActivate: [UserMachineGuard],
        data: {
            isSecondLevel: true,
            isNavigationHidden: true,
            isLegalLineHidden: true,
            guardType: MachineGuardType.TIMELOGGING,
            goToParentSegments: -2,
        } as RouteData,
        resolve: { [WorkstationResolvedDataKeys.WORKSTATION_DATA]: workstationDataResolver },
    },
    {
        path: `:${ROUTE_PARAMS.machineId}/${ROUTE_PATH.operations}`,
        component: OperationsPageComponent,
        canActivate: [UserMachineGuard],
        resolve: { [WorkstationResolvedDataKeys.WORKSTATION_DATA]: workstationDataResolver },
        children: [
            {
                path: `:${ROUTE_PARAMS.primaryKey}`,
                component: OperationComponent,
                data: {
                    goToParentSegments: -3,
                },
            },
        ],
        data: {
            isSecondLevel: true,
            navTitle: 'DC.OPERATIONS.TITLE',
            order: 1,
            id: ActivityRequired.OPERATION,
            isCustomTab: true,
            isLegalLineHidden: true,
            guardType: MachineGuardType.OPERATIONS,
            goToParentSegments: -2,
        } as RouteData,
    },
    {
        path: `:${ROUTE_PARAMS.machineId}/${ROUTE_PATH.activities}`,
        component: ActivitiesPageComponent,
        canActivate: [UserMachineGuard],
        data: {
            isSecondLevel: true,
            navTitle: 'DC.ACTIVITIES.TITLE',
            id: ActivityRequired.OTHER_ACTIVITY,
            isCustomTab: true,
            order: 2,
            goToParentSegments: -2,
            guardType: MachineGuardType.OPERATIONS,
        } as RouteData,
    },
    {
        path: `:${ROUTE_PARAMS.machineId}/${ROUTE_PATH.operations}/:${ROUTE_PARAMS.primaryKey}/details`,
        component: OperationDetailsPageComponent,
        resolve: { ...operationDetailsResolver(), ...operationDetailsNameResolver() },
        canActivate: [UserMachineGuard],
        data: {
            isSecondLevel: true,
            isNavigationHidden: true,
            isLegalLineHidden: true,
            guardType: MachineGuardType.OPERATIONS,
            breadcrumbs: [
                {
                    text: DC_APP_TITLE,
                    routerLink: [ROUTE_PARAMS.orgId, ROUTE_PATH.dashboard],
                },
                {
                    text: ROUTE_PARAMS.machineId,
                    routerLink: [
                        ROUTE_PARAMS.orgId,
                        ROUTE_PATH.dashboard,
                        ROUTE_PARAMS.machineId,
                        ROUTE_PATH.operations,
                    ],
                },
                {
                    text: ROUTE_PARAMS.primaryKey,
                },
            ],
        } as RouteData,
    },
    {
        path: `:${ROUTE_PARAMS.machineId}/${ROUTE_PATH.operations}/:${ROUTE_PARAMS.primaryKey}/comments`,
        component: CommentWorkflowPageComponent,
        resolve: { ...operationDetailsResolver(), ...operationDetailsNameResolver() },
        canActivate: [UserMachineGuard],
        data: {
            isSecondLevel: true,
            isNavigationHidden: true,
            isLegalLineHidden: true,
            guardType: MachineGuardType.OPERATIONS,
            breadcrumbs: [
                {
                    text: DC_APP_TITLE,
                    routerLink: [ROUTE_PARAMS.orgId, ROUTE_PATH.dashboard],
                },
                {
                    text: ROUTE_PARAMS.machineId,
                    routerLink: [
                        ROUTE_PARAMS.orgId,
                        ROUTE_PATH.dashboard,
                        ROUTE_PARAMS.machineId,
                        ROUTE_PATH.operations,
                    ],
                },
                {
                    text: ROUTE_PARAMS.primaryKey,
                },
            ],
        } as RouteData,
    },
];

export const DETAILS_WORKSTATION_ROUTES: Routes = [
    {
        path: `:${ROUTE_PARAMS.machineId}`,
        redirectTo: '',
    },
    {
        path: '',
        children: CHILDREN,
        resolve: {
            ...breadcrumbsWorkstationResolver(),
            ...machineActivityListResolver(),
            ...machineClassResolver(),
            [WorkstationResolvedDataKeys.MACHINE_SIGN_IN_TIMESTAMP]: machineSignInTimestampResolver,
        },
        data: {
            subNavigation: CHILDREN,
            isSecondLevel: true,
            isNavigationHidden: false,
            customTabs: 'OPERATION-TAB',
            breadcrumbs: [
                {
                    text: DC_APP_TITLE,
                    routerLink: [ROUTE_PARAMS.orgId, ROUTE_PATH.dashboard],
                },
                {
                    text: ROUTE_PARAMS.machineId,
                },
            ],
        } as RouteData,
    },
];
