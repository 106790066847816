<div class="toolbar-button-panel">
    <div class="toolbar-button-panel__request-delivery-button">
        @if (!stopButtonOnly && isRequestDeliveryButtonVisible) {
            <button
                mat-icon-button
                (click)="onRequestDeliveryButtonClick()"
                [matMenuTriggerFor]="
                    this.operationSettings?.deviceClass?.toLocaleLowerCase() ===
                    MachineClass.ID_SHAPECUTTER.toLocaleLowerCase()
                        ? requestDeliveryOptions
                        : null
                "
                [disabled]="!this.requestDeliveryOptionState?.isRequestDeliveryPossible"
            >
                <mat-icon svgIcon="hdmui:receivingGoods"></mat-icon>
            </button>

            <mat-menu #requestDeliveryOptions="matMenu">
                <button
                    mat-menu-item
                    (click)="onRequestDeliveryOptionClick(ShapeCutterTaskType.SEMIFINISH)"
                    [disabled]="!this.requestDeliveryOptionState?.isSemiFinishOptionEnabled"
                >
                    {{ 'DC.REQUEST_DELIVERY_DIALOG.SEMI-FINISHED_GOODS' | translate }}
                </button>
                <button
                    mat-menu-item
                    (click)="onRequestDeliveryOptionClick(ShapeCutterTaskType.TOOL)"
                    [disabled]="!this.requestDeliveryOptionState?.isToolOptionEnabled"
                >
                    {{ '*.tools' | translate }}
                </button>
                <button
                    mat-menu-item
                    (click)="onRequestDeliveryOptionClick(ShapeCutterTaskType.ALL)"
                    [disabled]="
                        !this.requestDeliveryOptionState?.isSemiFinishOptionEnabled ||
                        !this.requestDeliveryOptionState?.isToolOptionEnabled
                    "
                >
                    {{ 'DC.REQUEST_DELIVERY_DIALOG.SEMI-FINISHED_GOODS' | translate }} / {{ '*.tools' | translate }}
                </button>
            </mat-menu>
        }
    </div>
    <div>
        @if (!stopButtonOnly) {
            <button
                mat-icon-button
                [disabled]="this.pauseButtonDisabled"
                [class.toolbar-button-panel__active-action-button]="isPauseHighlighted"
                (click)="onPauseButtonClick()"
            >
                <mat-icon svgIcon="hdmui:videoControlPause"></mat-icon>
            </button>
        }

        <button
            mat-icon-button
            [disabled]="stopButtonDisabled"
            [class.toolbar-button-panel__active-action-button]="isStopHighlighted"
            (click)="onStopButtonClick()"
        >
            <mat-icon svgIcon="hdmui:videoControlStop"></mat-icon>
        </button>

        @if (!stopButtonOnly) {
            <button mat-icon-button [disabled]="reportButtonDisabled" (click)="onReportButtonClick()">
                <mat-icon svgIcon="hdmui:totalisatorStand"></mat-icon>
            </button>

            @if (operationSettings?.materialConsumption && organizationInfo?.consumptionReport) {
                <button
                    mat-icon-button
                    (click)="onConsumptionButtonClick()"
                    [disabled]="this.consumptionsButtonDisabled"
                >
                    <mat-icon svgIcon="hdmui:consumption"></mat-icon>
                </button>
            }
        }
    </div>
</div>
