<form class="hdmui-content consumption-form" [formGroup]="form">
    <div class="consumption-form__row-with-single-form-field">
        <mat-form-field>
            <mat-label> {{ 'WORKSTEPS.TABLE.MATERIAL' | translate }}</mat-label>
            <input #materialInput matInput formControlName="materialId" [matAutocomplete]="auto" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <data-terminal-custom-divider
                    [textClass]="'hdmui-caption'"
                    [text]="'H_SUGGESTIONS' | translate"
                ></data-terminal-custom-divider>
                @for (suggestion of recentMaterials$ | async; track suggestion) {
                    <mat-option [value]="suggestion.id">
                        <div>
                            <p class="hdmui-m-0">{{ suggestion.id }}</p>
                            <p class="hdmui-m-0 hdmui-font-small">
                                {{
                                    suggestion?.material?.materialDescription ||
                                        ('DC.REPORT_CONSUMPTION_DIALOG.UNKNOWN_MATERIAL' | translate)
                                }}
                            </p>
                        </div>
                    </mat-option>
                }
            </mat-autocomplete>
            <button
                mat-icon-button
                matSuffix
                (click)="handleScanningDialog($event, form.controls.materialId, materialInput)"
            >
                <mat-icon class="barcode__icon" svgIcon="hdmui:rteAlignmentJustify"></mat-icon>
            </button>
            @if (form.controls.materialId.hasError('required')) {
                <mat-error>{{ '*.required' | translate }}</mat-error>
            }
            @if (form.controls.materialId.hasError('maxlength')) {
                <mat-error>{{ 'MAX_STRING_LENGTH' | translate }}</mat-error>
            }
        </mat-form-field>
        @if (!form.controls.materialId.dirty) {
            <span>
                {{ 'DC.REPORT_CONSUMPTION_DIALOG.PROVIDE_MATERIAL_ID' | translate }}
            </span>
        } @else {
            <span
                class="consumption-form__material-name"
                [class.hdmui-warning-strong]="!existingMaterial()?.materialDescription"
            >
                {{
                    existingMaterial()?.materialDescription ||
                        ('DC.REPORT_CONSUMPTION_DIALOG.UNKNOWN_MATERIAL' | translate)
                }}
            </span>
        }
    </div>
    <div [class.consumption-form__row-with-multiple-form-fields]="batchAllowed()">
        <mat-form-field>
            <mat-label> {{ 'AMOUNT' | translate }}</mat-label>
            <input #amountInput matInput formControlName="amount" mask="separator.0" />
            <mat-hint
                align="start"
                [ngClass]="{ 'consumption-form__mat-hint--hidden': !existingMaterial()?.availableAmount }"
            >
                {{ 'DC.REPORT_CONSUMPTION_DIALOG.ON_STOCK' | translate }}
                {{ existingMaterial()?.availableAmount | number }}
            </mat-hint>
            @if (existingMaterial(); as material) {
                <span class="consumption-form__material-unit" matSuffix>{{ material.unitCaption }}</span>
            }
            @if (form.controls.amount.hasError('required')) {
                <mat-error>{{ '*.required' | translate }}</mat-error>
            }
            @if (form.controls.amount.hasError('min')) {
                <mat-error>{{ 'VALIDATION.MIN' | translate: { value: 0 } }}</mat-error>
            }
            @if (form.controls.amount.hasError('max')) {
                <mat-error
                    >{{ 'MM.APP.VALIDATION.MAX' | translate: { value: form.controls.amount.getError('max')?.max } }}
                </mat-error>
            }
        </mat-form-field>
        @if (batchAllowed()) {
            <mat-form-field>
                <mat-label> {{ 'DC.REPORT_CONSUMPTION_DIALOG.BATCH_NUMBER' | translate }}</mat-label>
                <input #batchInput matInput formControlName="batchNumber" />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="handleScanningDialog($event, form.controls.batchNumber, batchInput)"
                >
                    <mat-icon class="barcode__icon" svgIcon="hdmui:rteAlignmentJustify"></mat-icon>
                </button>
                @if (form.controls.batchNumber.hasError('maxlength')) {
                    <mat-error>{{ 'MAX_STRING_LENGTH' | translate }}</mat-error>
                }
            </mat-form-field>
        }
    </div>
    <mat-form-field>
        <mat-label>
            {{ '*.type' | translate }}
        </mat-label>
        <mat-select formControlName="type">
            @for (type of consumptionTypes; track type) {
                <mat-option [value]="type">
                    {{ 'DC.CONSUMPTION.TYPES.' + type | translate }}
                </mat-option>
            }
        </mat-select>
    </mat-form-field>

    @if (form.hasError('blackList')) {
        <div class="hdmui-my-4">
            <mat-error
                >{{
                    (batchAllowed()
                        ? 'DC.REPORT_CONSUMPTION_DIALOG.CONSUMPTION_FOR_MATERIAL_AND_BATCH_ALREADY_ADDED'
                        : 'DC.REPORT_CONSUMPTION_DIALOG.CONSUMPTION_FOR_MATERIAL_ALREADY_ADDED'
                    ) | translate
                }}
            </mat-error>
        </div>
    }
    <button
        mat-stroked-button
        class="hdmui-m-0 consumption-form__add-button"
        [disabled]="!form.valid"
        (click)="addConsumption()"
    >
        {{ 'DC.REPORT_CONSUMPTION_DIALOG.ADD_CONSUMPTION' | translate }}
    </button>
</form>
