<div class="hdmui hdmui-m-0 wrapper hdmui-container">
    <header>
        <mat-toolbar class="dialog-toolbar">
            <h2>
                {{ (hasStepper ? '*.action.report' : dialogData.i18nHeaderKey) | translate }}
            </h2>
            <button mat-icon-button (click)="onClose()">
                <mat-icon svgIcon="hdmui:x"></mat-icon>
            </button>
        </mat-toolbar>

        @if (hasStepper) {
            <p class="hdmui-font-small hdmui-font-bold hdmui-mb-0">{{ '*.step' | translate }} {{ selectedStep + 1 }}</p>
            <div class="stepper">
                <div class="stepper__button" [class.stepper__button--active]="selectedStep === 0"></div>
                <div class="stepper__button" [class.stepper__button--active]="selectedStep === 1"></div>
            </div>

            <p class="hdmui-mt-0">
                {{ (selectedStep === 0 ? dialogData.i18nHeaderKey : 'DC.REPORT_CONSUMPTION_DIALOG.TITLE') | translate }}
            </p>

            <mat-divider></mat-divider>

            @if (selectedStep === 1) {
                <data-terminal-consumption-add-form
                    [workstationId]="dialogData.consumptionsDialogData?.workstationId || ''"
                    [allMaterials]="dialogData.consumptionsDialogData?.allMaterials || []"
                    [batchAllowed]="!!dialogData.consumptionsDialogData?.batchAllowed"
                    [workStepLink]="dialogData.consumptionsDialogData?.workStepLink"
                    [consumptions]="consumptions()"
                    (consumptionAdd)="addConsumption($event)"
                ></data-terminal-consumption-add-form>
                <mat-divider></mat-divider>
            }
        }
    </header>

    <section class="hdmui-content">
        @if (hasStepper && selectedStep === 1) {
            <data-terminal-consumptions-preview
                [consumptions]="consumptions()"
                (consumptionRemove)="removeConsumption($event)"
            ></data-terminal-consumptions-preview>
        } @else {
            <ng-container *ngTemplateOutlet="reportLastAmountsFormTemplate"></ng-container>
        }
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="onSecondaryButtonClick()">
            {{ secondaryButtonText | translate }}
        </button>
        <button mat-flat-button (click)="onPrimaryButtonClick()" [disabled]="!primaryButtonEnabled">
            {{ primaryButtonText | translate }}
        </button>
    </footer>
</div>

<ng-template #reportLastAmountsFormTemplate>
    <form [formGroup]="reportAmountsForm" class="report">
        <div class="report-form">
            @if (dialogData.counterBoxTimestamp !== undefined && dialogData.counterBoxTimestamp !== '') {
                <p class="hdmui-caption">
                    {{ 'DC.REPORT_LAST_AMOUNTS.COUNTERBOX_MSG' | translate }}:
                    {{ dialogData.counterBoxTimestamp }}
                </p>
            }
            <div class="hdmui-m-3 report-form__container">
                @if (!dialogData.isManualMachine) {
                    <p class="hdmui-font-bold hdmui-m-0 hdmui-p-0 report-form__label-amounts">
                        {{ 'DC.OPERATIONS.OPERATION_DETAILS.AMOUNTS' | translate }}
                    </p>
                }
                @if (!dialogData.isManualMachine) {
                    <mat-form-field class="hdmui-mt-0 hdmui-pt-0 report-form__input">
                        <mat-label>
                            {{ 'DC.REPORT_LAST_AMOUNTS.GOOD' | translate }}
                        </mat-label>
                        <input
                            matInput
                            formControlName="goodAmount"
                            (blur)="onInputOut()"
                            #goodAmountInput
                            mask="separator.0"
                        />
                    </mat-form-field>
                }

                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__value-hint">
                    @if (dialogData.plannedGoodAmount - dialogData.currentGoodAmount > 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.TO_GO'
                                | translate
                                    : {
                                          value: dialogData.plannedGoodAmount - dialogData.currentGoodAmount | number,
                                      }
                        }}
                    }
                    @if (dialogData.plannedGoodAmount - dialogData.currentGoodAmount < 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.ABOVE'
                                | translate
                                    : {
                                          value:
                                              -(dialogData.plannedGoodAmount - dialogData.currentGoodAmount) | number,
                                      }
                        }}
                    }
                </p>
                @if (!dialogData.isManualMachine) {
                    <mat-form-field class="hdmui-m-0 hdmui-p-0 report-form__input">
                        <mat-label>
                            {{ 'DC.REPORT_LAST_AMOUNTS.WASTE' | translate }}
                        </mat-label>
                        <input matInput formControlName="wasteAmount" (blur)="onInputOut()" mask="separator.0" />
                    </mat-form-field>
                    <p class="hdmui-caption hdmui-m-0 hdmui-my-3 hdmui-p-0 report-form__info">
                        {{ 'DC.REPORT_LAST_AMOUNTS.GOOD_WASTE_INFO' | translate }}
                    </p>
                }
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__value-hint">
                    @if (dialogData.plannedWasteAmount - dialogData.currentWasteAmount > 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.TO_GO'
                                | translate
                                    : {
                                          value: dialogData.plannedWasteAmount - dialogData.currentWasteAmount | number,
                                      }
                        }}
                    }
                    @if (dialogData.plannedWasteAmount - dialogData.currentWasteAmount < 0) {
                        {{
                            'DC.REPORT_LAST_AMOUNTS.DIALOG.ABOVE'
                                | translate
                                    : {
                                          value:
                                              -(dialogData.plannedWasteAmount - dialogData.currentWasteAmount) | number,
                                      }
                        }}
                    }
                </p>

                <mat-form-field class="hdmui-mb-4">
                    <mat-label>{{ 'DC.REPORT_LAST_AMOUNTS.DIALOG.COMMENT' | translate }}</mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>
                <data-terminal-amount-report-details
                    [formData]="formData$ | async"
                    [operationData]="operationData$ | async"
                    [isFinishOperation]="dialogData.dialogType === OperationActionType.STOP"
                    [isManualMachine]="!!dialogData.isManualMachine"
                ></data-terminal-amount-report-details>
            </div>
        </div>
    </form>
</ng-template>
