import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule } from '@angular/material/core';
import { KeyValuePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { TranslateOperationName } from '../../../../../../feature-workstation-details/src/lib/pipes/translate-operation-name.pipe';
import { BatchInformationService } from '../../../services/batch-information/batch-information.service';
import { ConsumptionEntity } from '@data-terminal/shared-models';

export interface OperationBatchInformation {
    [key: string]: BatchInformation[];
}

export interface BatchInformation {
    deviceId: string;
    operationName: string;
    batchId: string;
    consumption: number;
    materialId: string;
}

@Component({
    selector: 'data-terminal-single-machine-batch-information',
    standalone: true,
    imports: [
        MatExpansionModule,
        MatRippleModule,
        KeyValuePipe,
        MatTableModule,
        TranslateOperationName,
        TranslateModule,
    ],
    templateUrl: './single-machine-batch-information.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleMachineBatchInformationComponent implements OnInit {
    @Input() machineId!: string;
    @Input() consumptions!: ConsumptionEntity[];

    readonly #batchInformationService = inject(BatchInformationService);
    batchInformation: OperationBatchInformation = {};
    machineName = '';
    operationName = '';

    displayedColumns: string[] = ['material', 'amount', 'batch'];

    ngOnInit(): void {
        this.batchInformation = this.#batchInformationService.prepareBatchInformation(this.consumptions);
        this.machineName = this.#batchInformationService.findMachineNameById(this.machineId);
        if (this.batchInformation) {
            this.operationName = Object.values(this.batchInformation)[0][0].operationName;
        }
    }
}
