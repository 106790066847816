import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeyValuePipe } from '@angular/common';

import { BatchInformationDialogData } from '@data-terminal/feature-dialogs';

import { SingleMachineBatchInformationComponent } from './single-machine-batch-information/single-machine-batch-information.component';
import { BatchInformationService } from '../../services/batch-information/batch-information.service';

@Component({
    standalone: true,
    imports: [TranslateModule, MatButton, MatDivider, SingleMachineBatchInformationComponent, KeyValuePipe],
    templateUrl: './batch-information-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchInformationDialogComponent {
    readonly #batchInformationService = inject(BatchInformationService);
    readonly #dialogRef = inject(MatDialogRef<BatchInformationDialogComponent>);
    readonly dialogData: BatchInformationDialogData = inject(MAT_DIALOG_DATA);

    close(): void {
        this.#dialogRef.close();
    }

    exportBatchInformation(): void {
        this.#batchInformationService.exportBatchInformationToCsv(
            this.dialogData.batchInformation,
            this.dialogData.jobId
        );
    }
}
