import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LoadingIndicatorComponentModule, SearchInputModule } from '@data-terminal/ui-presentational';
import { valueWhitelistedValidator } from '@data-terminal/utils';
import { BatchInformationDialogService } from '@data-terminal/feature-dialogs';

import { JobService } from '../../../../../feature-workstation-details/src/lib/services/job/job.service';

export interface JobSelectionForm {
    jobId: FormControl<string>;
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        TranslateModule,
        MatDividerModule,
        SearchInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        LoadingIndicatorComponentModule,
    ],
    templateUrl: './request-batch-information-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestBatchInformationDialogComponent implements OnInit {
    readonly #batchInformationDialogService = inject(BatchInformationDialogService);
    readonly #dialogRef = inject(MatDialogRef<RequestBatchInformationDialogComponent>);
    readonly #jobService = inject(JobService);

    readonly JOB_SUGGESTION_MIN_LENGTH = 3;

    jobIdSuggestions$: Observable<string[]> = of([]);
    jobSelectionForm!: FormGroup<JobSelectionForm>;

    ngOnInit(): void {
        this.initJobSelectionForm();
        this.listenForJobIdSuggestions();
    }

    cancel(): void {
        this.#dialogRef.close();
    }

    requestBatchInformation(): void {
        this.#dialogRef.close();
        this.#batchInformationDialogService
            .requestBatchInformation(this.jobSelectionForm.controls.jobId.value)
            .subscribe();
    }

    private initJobSelectionForm(): void {
        this.jobSelectionForm = new FormGroup<JobSelectionForm>({
            jobId: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.required, valueWhitelistedValidator([])],
            }),
        });
    }

    private listenForJobIdSuggestions(): void {
        this.jobIdSuggestions$ = this.jobIdFromControl.valueChanges.pipe(
            untilDestroyed(this),
            debounceTime(1000),
            distinctUntilChanged(),
            switchMap((jobId: string) => {
                if (jobId.length >= this.JOB_SUGGESTION_MIN_LENGTH) {
                    return this.#jobService.getJobList(jobId).pipe(
                        take(1),
                        catchError(() => of([]))
                    );
                } else {
                    return of([]);
                }
            }),
            map((jobList) => jobList.map((job) => job.jobId)),
            tap((jobIdList) => this.updateJobIdValueAndValidity(jobIdList))
        );
    }

    private updateJobIdValueAndValidity(jobIdList: string[]): void {
        this.jobIdFromControl.setValidators([Validators.required, valueWhitelistedValidator(jobIdList)]);
        this.jobIdFromControl.updateValueAndValidity();
    }

    get jobIdFromControl(): FormControl {
        return this.jobSelectionForm.get('jobId') as FormControl;
    }
}
