import { Component, inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, interval, take, takeWhile } from 'rxjs';
import { DateTime } from 'luxon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { HdmuiComponentsModule, HdmuiEmptyStatesModule, HdmuiInfoBoxModule } from '@heidelberg/hdmui-angular';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { findResolvedData, omitEntityValues, TimeFormatterPipeModule } from '@data-terminal/utils';
import {
    Activity,
    CaptureMode,
    ConsumptionPreview,
    MachineClass,
    TimeModeEntry,
    TimeModeStatus,
} from '@data-terminal/shared-models';
import { CustomDividerModule, LoadingIndicatorComponentModule } from '@data-terminal/ui-presentational';

import { PureTimeModeFacade } from '../../facades/pure-time-mode.facade';
import { PureTimeApiService } from '../../services/pure-time-api/pure-time-api.service';
import { TimeModeCard } from '../../models/time-mode-card.interface';
import { TimeModeEntriesToCardsPipe } from '../../pipes/time-mode-entries-to-cards.pipe';
import { calcCardMarginLeft, calcCardTopPx } from '../../functions/time-mode-card-dimension.functions';
import {
    LogTimeDialogComponent,
    LogTimeDialogData,
    LogTimeDialogResponse,
} from '../../components/log-time-dialog/log-time-dialog.component';
import { MachineSignInTimestampToHoursRangePipe } from '../../pipes/machine-sign-in-timestamp-to-hours-range.pipe';
import { LogTimeFormComponent, LogTimeFormValue } from '../../components/log-time-form/log-time-form.component';
import { durationStrToMs } from '../../functions/duration-str-to-ms.function';
import { DeleteTimeEntryDialogComponent } from '../../components/delete-time-entry-dialog/delete-time-entry-dialog.component';
import { ActivityNameByIdPipe } from '../../pipes/activity-name-by-id.pipe';
import { TotalEntriesDurationMsPipe } from '../../pipes/total-entries-duration-ms.pipe';
import { SubmitTimeEntriesConfirmDialogComponent } from '../../components/submit-time-entries-confirm-dialog/submit-time-entries-confirm-dialog.component';
import { LegalLineComponent } from '../../../../../../src/app/components/legal-line/legal-line.component';
import { WorkstationResolvedDataKeys } from '../../services/resolvers/workstation-resolved-data-keys.enum';
import { ConsumptionAddFormComponent } from '../../components/operations/operation-toolbar-buttons/consumption-add-form/consumption-add-form.component';
import { ConsumptionsPreviewComponent } from '../../components/operations/operation-toolbar-buttons/consumptions-preview/consumptions-preview.component';
import {
    defaultOperationSettings,
    WorkstationResolvedData,
} from '../../services/resolvers/workstation-data/workstation-data.resolver';
import { consumptionPreviewsToConsumptions } from '../../functions/consumption-preview-to-consumption.function';

export enum Mode {
    TIMELINE,
    CARDS,
}

@Pipe({
    standalone: true,
    name: 'draftEntries',
})
export class DraftEntriesPipe implements PipeTransform {
    transform(entries: TimeModeEntry[]): TimeModeEntry[] {
        return entries.filter(
            (entry) => entry.status === TimeModeStatus.DRAFT || entry.status === TimeModeStatus.ERROR
        );
    }
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-pure-time-mode-page',
    templateUrl: './pure-time-mode.page.component.html',
    styleUrls: ['./pure-time-mode.page.component.scss'],
    standalone: true,
    imports: [
        MatToolbarModule,
        MatButtonModule,
        HdmuiInfoBoxModule,
        MatDividerModule,
        CommonModule,
        MatCardModule,
        TimeModeEntriesToCardsPipe,
        HdmuiComponentsModule,
        HdmuiEmptyStatesModule,
        TranslateModule,
        MatIconModule,
        MatSidenavModule,
        MachineSignInTimestampToHoursRangePipe,
        TimeFormatterPipeModule,
        LogTimeFormComponent,
        ActivityNameByIdPipe,
        TotalEntriesDurationMsPipe,
        LoadingIndicatorComponentModule,
        MatProgressBarModule,
        DraftEntriesPipe,
        LegalLineComponent,
        ConsumptionAddFormComponent,
        ConsumptionsPreviewComponent,
        CustomDividerModule,
    ],
    providers: [PureTimeModeFacade, RxState, PureTimeApiService],
})
export class PureTimeModePageComponent implements OnInit {
    readonly #pureTimeModeFacade = inject(PureTimeModeFacade);
    readonly #dialog = inject(MatDialog);
    readonly #activatedRoute = inject(ActivatedRoute);
    readonly #ccAuthService = inject(CCAuthService);

    @ViewChild('detailsSidenav') detailsSidenav!: MatSidenav;

    public readonly state$ = this.#pureTimeModeFacade.state$;
    public readonly TimeModeStatus = TimeModeStatus;
    public readonly LOW_CARD_THRESHOLD_PX = 60;
    public readonly CARD_HOVER_EXTEND_THRESHOLD_PX = 32;
    public readonly Mode = Mode;
    public readonly today = new Date();
    public readonly timelineRefresher$ = interval(1000).pipe(
        filter(() => {
            const SECONDS_THRESHOLD = 5;
            const now = DateTime.now();
            const minutes = now.minute;
            const seconds = now.second;
            return minutes === 0 && seconds <= SECONDS_THRESHOLD; // it's not seconds === 0, just to make sure it 100% refreshes
        })
    );
    public readonly workstationResolvedData: WorkstationResolvedData;
    public readonly machineClass: MachineClass;
    public readonly activities: Activity[];
    public readonly machineId: string;

    public mode = Mode.TIMELINE;
    public selectedEntry: TimeModeEntry | undefined;

    public editEntryFormValue?: LogTimeFormValue;
    public isEditEntryFormValid = false;

    public editableConsumptions: ConsumptionPreview[] = [];
    public materialConsumptionsEnabled = false;

    constructor() {
        this.activities =
            findResolvedData<Activity[]>(this.#activatedRoute.snapshot, WorkstationResolvedDataKeys.ACTIVITIES) ?? [];

        this.machineClass =
            findResolvedData<MachineClass>(this.#activatedRoute.snapshot, WorkstationResolvedDataKeys.MACHINE_CLASS) ??
            MachineClass.DEFAULT;

        this.workstationResolvedData = findResolvedData<WorkstationResolvedData>(
            this.#activatedRoute.snapshot,
            WorkstationResolvedDataKeys.WORKSTATION_DATA
        ) || { operationSettings: defaultOperationSettings, materials: [] };

        this.machineId = this.#activatedRoute.snapshot.params.machineId;
    }

    ngOnInit(): void {
        this.#pureTimeModeFacade.setDeviceId(this.machineId);
        this.#pureTimeModeFacade.setMachineSignInTimestamp(
            findResolvedData<number>(
                this.#activatedRoute.snapshot,
                WorkstationResolvedDataKeys.MACHINE_SIGN_IN_TIMESTAMP
            ) || 0
        );
        this.#pureTimeModeFacade.triggerFetch();
    }

    public logTime(machineSignInTimestamp: number, entries: TimeModeEntry[]): void {
        const { operationSettings, materials } = this.workstationResolvedData;

        this.#dialog
            .open<LogTimeDialogComponent, LogTimeDialogData, LogTimeDialogResponse>(LogTimeDialogComponent, {
                maxHeight: '90vh',
                height: '100%',
                width: '100%',
                maxWidth: '620px',
                disableClose: true,
                autoFocus: false,
                data: {
                    activities: this.activities,
                    machineClass: this.machineClass,
                    operationSettings,
                    materials,
                    lastLoggedActivityTimestamp: this.calcLastEntryEndTimestampMs(entries, machineSignInTimestamp),
                    workstationId: this.machineId,
                },
            })
            .afterClosed()
            .pipe(
                take(1),
                filter((data) => !!data)
            )
            .subscribe((response) => {
                if (!response) {
                    return;
                }

                const logTimeFormValue = response.logTimeFormValue;
                const consumptions = response.consumptions;

                const entry: TimeModeEntry = {
                    email: this.#ccAuthService.getCurrentUser()?.email || '',
                    shiftStart: machineSignInTimestamp.toString(),
                    deviceId: this.machineId,
                    duration: durationStrToMs(logTimeFormValue.duration),
                    status: TimeModeStatus.DRAFT,
                    jobId: logTimeFormValue.jobId || undefined,
                    activity: logTimeFormValue.activity?.actId || '',
                    type: CaptureMode.TIME,
                    goodAmount: logTimeFormValue.goodAmount || undefined,
                    wasteAmount: logTimeFormValue.wasteAmount || undefined,
                    consumptions: consumptions,
                    submissionId: crypto.randomUUID(),
                    comment: logTimeFormValue.comment || undefined,
                };
                this.#pureTimeModeFacade.updateEntry(entry);
            });
    }

    public submitTimes(): void {
        this.#dialog
            .open<SubmitTimeEntriesConfirmDialogComponent>(SubmitTimeEntriesConfirmDialogComponent)
            .afterClosed()
            .pipe(
                take(1),
                filter((confirmed) => !!confirmed)
            )
            .subscribe(() => {
                this.#pureTimeModeFacade.submitEntries();
            });
    }

    public calcCardTopPx(cards: TimeModeCard[], machineSignInTimestamp: number, index: number): number {
        return calcCardTopPx(cards, machineSignInTimestamp, index);
    }

    public switchMode(): void {
        this.mode = this.mode === Mode.TIMELINE ? Mode.CARDS : Mode.TIMELINE;
    }

    public calcCardMarginLeft(cards: TimeModeCard[], index: number): number {
        return calcCardMarginLeft(cards, index, this.CARD_HOVER_EXTEND_THRESHOLD_PX);
    }

    public openDetails(entry: TimeModeEntry): void {
        this.selectedEntry = entry;
        this.editableConsumptions = entry.consumptions;
        void this.detailsSidenav.open();

        this.detailsSidenav._closedStream.pipe(takeWhile(() => this.selectedEntry !== undefined)).subscribe(() => {
            this.selectedEntry = undefined;
            this.editableConsumptions = [];
            this.materialConsumptionsEnabled = false;
        });
    }

    public calcLastEntryEndTimestampMs(entries: TimeModeEntry[], machineSignInTimestamp: number): number {
        return entries.reduce((accumulator, entry) => accumulator + entry.duration, machineSignInTimestamp);
    }

    public updateEntry(): void {
        if (this.selectedEntry && this.isEditEntryFormValid && this.editEntryFormValue) {
            this.#pureTimeModeFacade.updateEntry({
                ...omitEntityValues(this.selectedEntry),
                status: TimeModeStatus.DRAFT,
                duration: durationStrToMs(this.editEntryFormValue.duration),
                activity: this.editEntryFormValue.activity?.actId || '',
                jobId: this.editEntryFormValue?.jobId || undefined,
                goodAmount: this.editEntryFormValue?.goodAmount || undefined,
                wasteAmount: this.editEntryFormValue?.wasteAmount || undefined,
                comment: this.editEntryFormValue?.comment || undefined,
                consumptions: consumptionPreviewsToConsumptions(this.editableConsumptions),
            } as TimeModeEntry);

            void this.detailsSidenav.close();
        }
    }

    public deleteEntry(): void {
        this.#dialog
            .open<DeleteTimeEntryDialogComponent>(DeleteTimeEntryDialogComponent)
            .afterClosed()
            .pipe(
                take(1),
                filter((confirmed) => !!confirmed)
            )
            .subscribe(() => {
                if (this.selectedEntry) {
                    this.#pureTimeModeFacade.deleteEntry(omitEntityValues(this.selectedEntry));

                    void this.detailsSidenav.close();
                }
            });
    }

    public onEditEntryFormValueChange(value: LogTimeFormValue): void {
        this.editEntryFormValue = value;
    }

    public onEditEntryFormValidityChange(isValid: boolean): void {
        this.isEditEntryFormValid = isValid;
    }

    public onMaterialConsumptionsAllowedChange(materialConsumptionsAllowed: boolean): void {
        this.materialConsumptionsEnabled =
            materialConsumptionsAllowed && this.workstationResolvedData.operationSettings.materialConsumption;
        if (!this.materialConsumptionsEnabled) {
            this.editableConsumptions = [];
        }
    }

    public addConsumption(consumption: ConsumptionPreview): void {
        if (this.editableConsumptions) {
            this.editableConsumptions = [...this.editableConsumptions, consumption];
        }
    }

    public removeConsumption(index: number): void {
        if (this.editableConsumptions) {
            this.editableConsumptions = this.editableConsumptions.filter((_, i) => i !== index);
        }
    }
}
