<mat-toolbar>
    <data-terminal-search-input
        [sessionStorageKey]="getMachineString()"
        [searchPhrase]="searchPhrase"
        (searchPhraseChange)="onSearchInputChange($event)"
        (barcodeClick)="onBarcodeClick()"
        [autoCompleteSuggestionsPool]="jobIdSuggestions"
        [autoCompleteCaption]="'DC.OPERATION_LIST.AUTOCOMPLETE_HINT' | translate"
    ></data-terminal-search-input>
    <span class="spacer"></span>
    @if (showFilterBadge) {
        <button mat-icon-button matBadge (click)="onFilterClick()" [class]="'hdmui-badge-highlight hdmui-badge-minor'">
            <mat-icon svgIcon="hdmui:filter"></mat-icon>
        </button>
    } @else {
        <button mat-icon-button (click)="onFilterClick()">
            <mat-icon svgIcon="hdmui:filter"></mat-icon>
        </button>
    }

    <mat-divider vertical></mat-divider>
    <button mat-icon-button (click)="onOperationAdd()">
        <mat-icon svgIcon="hdmui:plusCircled"></mat-icon>
    </button>
</mat-toolbar>

<section class="operation-list hdmui-container">
    @if (runningOperations.length) {
        <header>
            <data-terminal-custom-divider
                [textClass]="'hdmui-caption'"
                [text]="'DC.OPERATION_LIST.RUNNING_OPERATION' | translate"
            ></data-terminal-custom-divider>
            <div class="operation-list__column running-operation">
                @for (operation of runningOperations; track identify($index, operation)) {
                    <data-terminal-operation-card
                        [id]="operation.primaryKey"
                        [operation]="operation"
                        [routerLink]="'./' + operation.primaryKey"
                        [attrOrder]="(attrOrder$ | async) || []"
                        [isSelected]="selectedOperation && operation.primaryKey === selectedOperation.primaryKey"
                        (cardSelect)="onCardSelect($event)"
                        [runningOnOtherMachine]="false"
                        [hasTaskInformation]="isTaskPilotEnabled"
                    ></data-terminal-operation-card>
                }
            </div>
            <mat-divider></mat-divider>
        </header>
    }

    <div class="operation-list__content hdmui-content hdmui-p-0">
        <div class="hdmui-my-3">
            <data-terminal-custom-divider
                [textClass]="'hdmui-caption'"
                text="{{ 'DC.OPERATION_LIST.OPERATIONS' | translate }} ({{ notRunningOperations.length }})"
            ></data-terminal-custom-divider>
        </div>

        @if (
            (operations.length === 0 && otherDeviceOperations.length === 0 && finishedOperations.length === 0) ||
            (notRunningOperations.length === 0 && runningOperations.length === 0)
        ) {
            <div class="empty-states__container">
                <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                    <hdmui-empty-states-title
                        >{{ 'DC.OPERATION_LIST.NOT_FOUND' | translate }}
                    </hdmui-empty-states-title>
                    <hdmui-empty-states-description
                        >{{ 'DC.SEARCH.EMPTY.DESCRIPTION' | translate }}
                    </hdmui-empty-states-description>
                </hdmui-empty-states>
            </div>
        }

        <div class="operation-list__column">
            @for (operation of notRunningOperations; track identify($index, operation)) {
                <data-terminal-operation-card
                    [id]="operation.primaryKey"
                    [operation]="operation"
                    [routerLink]="'./' + operation.primaryKey"
                    [attrOrder]="(attrOrder$ | async) || []"
                    [isSelected]="selectedOperation && operation.primaryKey === selectedOperation.primaryKey"
                    (cardSelect)="onCardSelect($event)"
                    [runningOnOtherMachine]="false"
                    [hasTaskInformation]="isTaskPilotEnabled"
                ></data-terminal-operation-card>
            }
        </div>

        <div class="hdmui-my-3">
            <data-terminal-custom-divider
                [textClass]="'hdmui-caption'"
                [text]="'DC.OPERATION_LIST.EXTRA_FUNCTIONS' | translate"
            ></data-terminal-custom-divider>
        </div>

        <div class="hdmui-px-4">
            <p class="hdmui-mx-0 special-functions__info">
                {{ 'DC.OPERATION-LIST.SPECIAL-OPERATIONS-INFO-TEXT' | translate }}
            </p>
            <button
                mat-stroked-button
                class="operation-list__button hdmui-mx-0"
                (click)="onCreateUnplannedWorkInstant()"
                [disabled]="!extraFunctionsEnabled"
            >
                {{ 'DC.OPERATION_LIST.BUTTON_CREATE_UNPLANNED_INSTANT' | translate }}
            </button>
            <button
                mat-stroked-button
                class="operation-list__button hdmui-mx-0"
                (click)="loadFinishedOperations()"
                [disabled]="!extraFunctionsEnabled"
            >
                {{ 'DC.OPERATION_LIST.BUTTON_FINISHED' | translate }}
            </button>
            <button
                mat-stroked-button
                class="operation-list__button hdmui-mx-0"
                (click)="loadOperationsByDeviceClass()"
                [disabled]="!extraFunctionsEnabled"
            >
                {{ 'DC.OPERATION_LIST.BUTTON_ALL_BY_DEVICE_CLASS' | translate }}
            </button>
        </div>

        @if (finishedOperations.length) {
            <div class="hdmui-my-3">
                <data-terminal-custom-divider
                    [textClass]="'hdmui-caption'"
                    [text]="'DC.OPERATION_LIST.FINISHED_OPERATIONS' | translate"
                ></data-terminal-custom-divider>
            </div>
            <div class="operation-list__column">
                @for (operation of finishedOperations; track identify($index, operation)) {
                    <data-terminal-operation-card
                        [id]="operation.primaryKey"
                        [operation]="operation"
                        [routerLink]="'./' + operation.primaryKey"
                        [attrOrder]="(attrOrder$ | async) || []"
                        [isSelected]="selectedOperation && operation.primaryKey === selectedOperation.primaryKey"
                        (cardSelect)="onCardSelect($event)"
                        [hasTaskInformation]="isTaskPilotEnabled"
                    ></data-terminal-operation-card>
                }
            </div>
        }

        @if (otherDeviceOperations.length) {
            <div class="hdmui-my-3">
                <data-terminal-custom-divider
                    [textClass]="'hdmui-caption'"
                    [text]="'DC.OPERATION_LIST.JOBS_FROM_OTHER_DEVICE' | translate"
                ></data-terminal-custom-divider>
            </div>
            <div class="operation-list__column">
                @for (operation of otherDeviceOperations; track identify($index, operation)) {
                    <data-terminal-operation-card
                        [id]="operation.primaryKey"
                        [operation]="operation"
                        [routerLink]="'./' + operation.primaryKey"
                        [attrOrder]="(attrOrder$ | async) || []"
                        [isSelected]="selectedOperation && operation.primaryKey === selectedOperation.primaryKey"
                        (cardSelect)="onCardSelect($event)"
                        [hasTaskInformation]="isTaskPilotEnabled"
                    ></data-terminal-operation-card>
                }
            </div>
        }
    </div>
</section>
